<template>
	<transition name="pane-program">
		<div class="pane pane-program" id="rsencours">
			<div class="content vb-content" style="display: block; overflow: hidden scroll; height: 100%; width: 100%; box-sizing: content-box; padding-right: 20px;background-color: white;" id="RsEx">
				<div class="pan-titre-programme" id="pan-titre-rs">
					<div class="module_ret">
						<div class="btns fermer" :title="$t('Fermer')"  @click="expand_rs();$store.dispatch('set_help_rs', {aide:false})"><icon file="fermer" :size="16" /></div>
						<div class="btns print" :title="$t('imprimer')" @click="generateRsImage(expanded_rs.id);"><icon file="download" :size="24" />&nbsp;</div>
<!--						<div class="btns chatbox"><Button style="height:60px;" class="p-button-text" icon="pi pi-comments" onclick="$('#zohohc-asap-web-launcherbox').click();" /></div>-->
						<div class="btns chatbox" onclick="$('#zohohc-asap-web-launcherbox').click();"><icon file="question" :size="24" />&nbsp;</div>
						<div v-if="expanded_rs.pc" class="indice_confiance" :class="{ pc : true }">
							{{ $t('fiche_projet') }}
						</div>
						<div v-else class="indice_confiance" :class="{ pc : true }">
							{{ $t('Fiche résidence services') }}
						</div>						
					</div>
					<br/>
				</div>
				<div><br><br><br><br></div>
				<div v-if="expanded_rs && !expanded_rs.pc">
					<div class="program" :class="{ inactif: false, selected: expanded_rs.selected }">
						<div class="program-main" style="height:285px;">
							<a target="_blank" :href="'https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=' + expanded_rs.location.lat + ',' + expanded_rs.location.lon">
								<div class="image">
									<img :src="expanded_rs.url_photo" alt="">
								</div>
							</a>
						</div>
						<div class="program-main" style="padding: 8px !important;">
							<div class="program-content">
								<p class="name" style="text-align: center;" v-if="expanded_rs.nature"><b><span class="cible">{{ expanded_rs.nature }}</span></b></p>
							</div>
						</div>
					</div>
					<div class="program-detail">
						<div class="program-detail-content" style="padding-top: 8px !important;">
							<div class="program-detail-info">
								<div class="program-maj" v-if="expanded_rs.dateindex">
									<div v-if="expanded_rs.date_maj" style="font-size: 14px;" :class="{recent: expanded_rs.date_maj.substring(0, 4)=='2023' && expanded_rs.nature=='RÉSIDENCE SERVICES SÉNIORS (RSS) LIBRE'}">{{ $t('donnees_a_jour_au') }} {{expanded_rs.date_maj.substring(8,10)}}/{{expanded_rs.date_maj.substring(5,7)}}/{{expanded_rs.date_maj.substring(0, 4)}}</div>
									<div><br v-if="expanded_rs.date_maj"><span v-if="!expanded_rs.date_maj">{{ $t('donnees') }}</span> {{ $t('publiees_le') }} {{expanded_rs.dateindex.substring(8,10)}}/{{expanded_rs.dateindex.substring(5,7)}}/{{expanded_rs.dateindex.substring(0, 4)}}</div>
								</div>
								<br>
								<ul class="list-none p-2 m-0">
									<li>
										<ul class="list-none p-0 m-0 overflow-hidden">
											<li v-if="expanded_rs.cible=='TOURISTE' && expanded_rs.etoiles">
												<a v-ripple class="flex align-items-center cursor-default p-3 hover:surface-100 border-round transition-colors transition-duration-150 p-ripple">
													<span class="font-medium" style="color: #6b23f1"><Rating :value="expanded_rs.etoiles" :readonly="true" :stars="5" :cancel="false" /></span>
												</a>
											</li>
											<li v-if="expanded_rs.nb_logement">
												<div style="display: flex;" class="flex align-items-center cursor-default p-3 hover:surface-100 border-round transition-colors transition-duration-150 p-ripple">
													<i class="pi pi-building mr-2 text-700"></i>
													<span class="font-medium text-700">{{expanded_rs.nb_logement}} {{$t('logement')}}</span><span v-if="expanded_rs.nb_logement>1">s</span>
													<div class="pl-5" style="display: flex;">
														<div class="pl-3 pr-2" v-if="expanded_rs.tc_lre_rse_nombre_logements!=null">
															<icon file="1" :size="24" class="mr-2 text-700" v-if="expanded_rs.tc_lre_rse_nombre_logements!=null"/>
															<span class="font-medium text-700" v-if="expanded_rs.tc_lre_rse_nombre_logements!=null">{{expanded_rs.tc_lre_rse_nombre_logements}} chambre</span><span v-if="expanded_rs.tc_lre_rse_nombre_logements>1">s</span>
														</div>
														<div class="pl-3 pr-2" v-if="expanded_rs.tstudio_lre_rse_nombre_logements!=null || expanded_rs.t1_lre_rse_nombre_logements!=null || expanded_rs.t1b_lre_rse_nombre_logements!=null">
															<icon file="1" :size="24" class="mr-2 text-700" v-if="expanded_rs.tstudio_lre_rse_nombre_logements!=null || expanded_rs.t1_lre_rse_nombre_logements!=null || expanded_rs.t1b_lre_rse_nombre_logements!=null"/>
															<span class="font-medium text-700" v-if="expanded_rs.tstudio_lre_rse_nombre_logements!=null || expanded_rs.t1_lre_rse_nombre_logements!=null || expanded_rs.t1b_lre_rse_nombre_logements!=null">{{expanded_rs.tstudio_lre_rse_nombre_logements + expanded_rs.t1_lre_rse_nombre_logements + expanded_rs.t1b_lre_rse_nombre_logements}} {{$t('T1')}}</span>
														</div>
														<div class="pl-3 pr-2" v-if="expanded_rs.t2_lre_rse_nombre_logements!=null">
															<icon file="2" :size="24" class="mr-2 text-700" v-if="expanded_rs.t2_lre_rse_nombre_logements!=null"/>
															<span class="font-medium text-700" v-if="expanded_rs.t2_lre_rse_nombre_logements!=null">{{expanded_rs.t2_lre_rse_nombre_logements}} {{$t('T2')}}</span>
														</div>
														<div class="pl-3 pr-2" v-if="expanded_rs.t3_lre_rse_nombre_logements!=null">
															<icon file="3" :size="24" class="mr-2 text-700" v-if="expanded_rs.t3_lre_rse_nombre_logements!=null"/>
															<span class="font-medium text-700" v-if="expanded_rs.t3_lre_rse_nombre_logements!=null">{{expanded_rs.t3_lre_rse_nombre_logements}} {{$t('T3')}}</span>
														</div>
														<div class="pl-3 pr-2" v-if="expanded_rs.t4_lre_rse_nombre_logements!=null">
															<icon file="4" :size="24" class="mr-2 text-700" v-if="expanded_rs.t4_lre_rse_nombre_logements!=null"/>
															<span class="font-medium text-700" v-if="expanded_rs.t4_lre_rse_nombre_logements!=null">{{expanded_rs.t4_lre_rse_nombre_logements}} {{$t('T4')}}</span>
														</div>
														<div class="pl-3 pr-2" v-if="expanded_rs.t5_lre_rse_nombre_logements!=null">
															<icon file="5" :size="24" class="mr-2 text-700" v-if="expanded_rs.t5_lre_rse_nombre_logements!=null"/>
															<span class="font-medium text-700" v-if="expanded_rs.t5_lre_rse_nombre_logements!=null">{{expanded_rs.t5_lre_rse_nombre_logements}} {{$t('T5')}}</span>
														</div>
													</div>
												</div>
											</li>
											
											<li v-if="expanded_rs.gestionnaire">
												<a v-ripple class="flex align-items-center cursor-default p-3 hover:surface-100 border-round transition-colors transition-duration-150 p-ripple">
													<i class="pi pi-tag mr-2 text-700"></i>
													<span class="font-medium text-700">{{expanded_rs.nom}}</span>
												</a>
											</li>
											<li v-if="expanded_rs.gestionnaire">
												<a v-ripple class="flex align-items-center cursor-default p-3 hover:surface-100 border-round transition-colors transition-duration-150 p-ripple">
													<i class="pi pi-user mr-2 text-700"></i>
													<span class="font-medium text-700">{{expanded_rs.gestionnaire}}</span>
												</a>
											</li>
											<li v-if="expanded_rs && expanded_rs.location && expanded_rs.location.lat">
												<a v-ripple class="flex align-items-center cursor-default p-3 hover:surface-100 border-round transition-colors transition-duration-150 p-ripple">
													<i class="pi pi-map-marker mr-2 text-700"></i>
													<span class="font-medium text-700">{{ expanded_rs.adresse }}, {{ expanded_rs.code_postal }} {{ expanded_rs.ville }}</span>
													<span class="inline-flex align-items-center justify-content-center ml-auto bg-blue-100 text-blue-900 border-circle" style="min-width: 2rem; height: 2rem"><a target="_blank" :href="'https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=' + expanded_rs.location.lat + ',' + expanded_rs.location.lon"><img alt="streetview" src="https://www.myadequation.fr/EFOCUS/streetview.png" :title="$t('voir_sur_google_street_view')"/></a></span>
												</a>
											</li>
											<li v-if="expanded_rs.ouverture">
												<a v-ripple class="flex align-items-center cursor-default p-3 hover:surface-100 border-round transition-colors transition-duration-150 p-ripple">
													<i class="pi pi-calendar mr-2 text-700"></i>
													<span class="font-medium text-700">{{expanded_rs.ouverture}}</span>
												</a>
											</li>
										</ul>
									</li>
								</ul>

								<div v-if="app.user.rs_option.includes('efocus-rs-option-taux-occupation')" class="program-detail-info-content">
									<table>
										<tr><td colspan="3" style="background-color: white;"></td></tr>
										
										<tr class="titre1">
											<th id="txocc">{{ $t('taux_doccupation') }}</th>
										</tr>
										<tr>
											<td :title="expanded_rs.nouvelle_residence === 1 ? 'Le taux doit être interprété différemment en raison de la fraîcheur de la résidence' : ''" v-if="expanded_rs.taux_occupation" :class="getTxtTauxClass(expanded_rs.taux_occupation)">{{Math.round(parseFloat(expanded_rs.taux_occupation)*100)}} %</td>
											<td :title="expanded_rs.nouvelle_residence === 1 ? 'Le taux doit être interprété différemment en raison de la fraîcheur de la résidence' : ''" v-else-if="expanded_rs.txo_libelle" :class="getTxtClass(expanded_rs.txo_libelle)">{{expanded_rs.txo_libelle}} </td>
											<td v-else>-</td>
										</tr>
									</table>
								</div>
								</div>
							<div class="program-detail-info" v-if="app.user.rs_option.includes('efocus-rs-option-loyer')">
								<div class="program-detail-info-content">
									<table>
										<tr><th id="col1"></th><th id="col2" rowspan=2>{{ $t('nombre_de_logements') }}</th><th id="col3" colspan=3>{{ $t('surfaces_en_m²') }}</th><th id="col4" colspan=3>{{ $t('loyers_mensuels') }}</th></tr>
										<tr><th id="col5"></th><th id="col6">{{ $t('min') }}.</th><th id="col7">{{ $t('moy.') }}</th><th id="col8">{{ $t('max') }}.</th><th id="col9">{{ $t('min') }}.</th><th id="col10">{{ $t('moy.') }}</th><th id="col11">{{ $t('max') }}.</th></tr>
										<tr v-if="expanded_rs.tc_lre_rse_nombre_logements||expanded_rs.tc_surface_min||expanded_rs.tc_surface_moy||expanded_rs.tc_surface_max||expanded_rs.tc_loyer_min||expanded_rs.tc_loyer_moy||expanded_rs.tc_loyer_max">
											<td>{{ $t('chambre') }}</td>
											<td v-if="expanded_rs.tc_lre_rse_nombre_logements">{{expanded_rs.tc_lre_rse_nombre_logements}}</td><td v-else>-</td>
											<td v-if="expanded_rs.tc_surface_min">{{expanded_rs.tc_surface_min}}</td><td v-else>-</td>
											<td v-if="expanded_rs.tc_surface_moy">{{expanded_rs.tc_surface_moy}}</td><td v-else>-</td>
											<td v-if="expanded_rs.tc_surface_max">{{expanded_rs.tc_surface_max}}</td><td v-else>-</td>
											<td v-if="expanded_rs.tc_loyer_min">{{expanded_rs.tc_loyer_min}}</td><td v-else>-</td>
											<td v-if="expanded_rs.tc_loyer_moy">{{expanded_rs.tc_loyer_moy}}</td><td v-else>-</td>
											<td v-if="expanded_rs.tc_loyer_max">{{expanded_rs.tc_loyer_max}}</td><td v-else>-</td>
										</tr>
										<tr v-if="expanded_rs.tstudio_lre_rse_nombre_logements||expanded_rs.tstudio_surface_min||expanded_rs.tstudio_surface_moy||expanded_rs.tstudio_surface_max||expanded_rs.tstudio_loyer_min||expanded_rs.tstudio_loyer_moy||expanded_rs.tstudio_loyer_max">
											<td>{{ $t('studio') }}</td>
											<td v-if="expanded_rs.tstudio_lre_rse_nombre_logements">{{ expanded_rs.tstudio_lre_rse_nombre_logements }}</td><td v-else>-</td>
											<td v-if="expanded_rs.tstudio_surface_min">{{expanded_rs.tstudio_surface_min}}</td><td v-else>-</td>
											<td v-if="expanded_rs.tstudio_surface_moy">{{expanded_rs.tstudio_surface_moy}}</td><td v-else>-</td>
											<td v-if="expanded_rs.tstudio_surface_max">{{expanded_rs.tstudio_surface_max}}</td><td v-else>-</td>
											<td v-if="expanded_rs.tstudio_loyer_min">{{expanded_rs.tstudio_loyer_min}}</td><td v-else>-</td>
											<td v-if="expanded_rs.tstudio_loyer_moy">{{expanded_rs.tstudio_loyer_moy}}</td><td v-else>-</td>
											<td v-if="expanded_rs.tstudio_loyer_max">{{expanded_rs.tstudio_loyer_max}}</td><td v-else>-</td>
										</tr>
										<tr>
											<td>{{ $t('t1') }}</td>
											<td v-if="expanded_rs.t1_nb">{{expanded_rs.t1_nb}}</td><td v-else-if="expanded_rs.t1_lre_rse_nombre_logements">{{ expanded_rs.t1_lre_rse_nombre_logements }}</td><td v-else>-</td>
											<td v-if="expanded_rs.t1_surface_min">{{expanded_rs.t1_surface_min}}</td><td v-else>-</td>
											<td v-if="expanded_rs.t1_surface_moy">{{expanded_rs.t1_surface_moy}}</td><td v-else>-</td>
											<td v-if="expanded_rs.t1_surface_max">{{expanded_rs.t1_surface_max}}</td><td v-else>-</td>
											<td v-if="expanded_rs.t1_loyer_min">{{expanded_rs.t1_loyer_min}}</td><td v-else>-</td>
											<td v-if="expanded_rs.t1_loyer_moy">{{expanded_rs.t1_loyer_moy}}</td><td v-else>-</td>
											<td v-if="expanded_rs.t1_loyer_max">{{expanded_rs.t1_loyer_max}}</td><td v-else>-</td>
										</tr>
										<tr v-if="expanded_rs.t1b_lre_rse_nombre_logements||expanded_rs.t1b_surface_min||expanded_rs.t1b_surface_moy||expanded_rs.t1b_surface_max||expanded_rs.t1b_loyer_min||expanded_rs.t1b_loyer_moy||expanded_rs.t1b_loyer_max">
											<td>{{ $t('t1') }} bis</td>
											<td v-if="expanded_rs.t1b_lre_rse_nombre_logements">{{ expanded_rs.t1b_lre_rse_nombre_logements }}</td><td v-else>-</td>
											<td v-if="expanded_rs.t1b_surface_min">{{expanded_rs.t1b_surface_min}}</td><td v-else>-</td>
											<td v-if="expanded_rs.t1b_surface_moy">{{expanded_rs.t1b_surface_moy}}</td><td v-else>-</td>
											<td v-if="expanded_rs.t1b_surface_max">{{expanded_rs.t1b_surface_max}}</td><td v-else>-</td>
											<td v-if="expanded_rs.t1b_loyer_min">{{expanded_rs.t1b_loyer_min}}</td><td v-else>-</td>
											<td v-if="expanded_rs.t1b_loyer_moy">{{expanded_rs.t1b_loyer_moy}}</td><td v-else>-</td>
											<td v-if="expanded_rs.t1b_loyer_max">{{expanded_rs.t1b_loyer_max}}</td><td v-else>-</td>
										</tr>
										<tr>
											<td>{{ $t('t2') }}</td>
											<td v-if="expanded_rs.t2_lre_rse_nombre_logements">{{expanded_rs.t2_lre_rse_nombre_logements}}</td><td v-else>-</td>
											<td v-if="expanded_rs.t2_surface_min">{{expanded_rs.t2_surface_min}}</td><td v-else>-</td>
											<td v-if="expanded_rs.t2_surface_moy">{{expanded_rs.t2_surface_moy}}</td><td v-else>-</td>
											<td v-if="expanded_rs.t2_surface_max">{{expanded_rs.t2_surface_max}}</td><td v-else>-</td>
											<td v-if="expanded_rs.t2_loyer_min">{{expanded_rs.t2_loyer_min}}</td><td v-else>-</td>
											<td v-if="expanded_rs.t2_loyer_moy">{{expanded_rs.t2_loyer_moy}}</td><td v-else>-</td>
											<td v-if="expanded_rs.t2_loyer_max">{{expanded_rs.t2_loyer_max}}</td><td v-else>-</td>
										</tr>
										<tr>
											<td>{{ $t('t3') }}</td>
											<td v-if="expanded_rs.t3_lre_rse_nombre_logements">{{expanded_rs.t3_lre_rse_nombre_logements}}</td><td v-else>-</td>
											<td v-if="expanded_rs.t3_surface_min">{{expanded_rs.t3_surface_min}}</td><td v-else>-</td>
											<td v-if="expanded_rs.t3_surface_moy">{{expanded_rs.t3_surface_moy}}</td><td v-else>-</td>
											<td v-if="expanded_rs.t3_surface_max">{{expanded_rs.t3_surface_max}}</td><td v-else>-</td>
											<td v-if="expanded_rs.t3_loyer_min">{{expanded_rs.t3_loyer_min}}</td><td v-else>-</td>
											<td v-if="expanded_rs.t3_loyer_moy">{{expanded_rs.t3_loyer_moy}}</td><td v-else>-</td>
											<td v-if="expanded_rs.t3_loyer_max">{{expanded_rs.t3_loyer_max}}</td><td v-else>-</td>
										</tr>
										<tr v-if="expanded_rs.t4_lre_rse_nombre_logements||expanded_rs.t4_surface_min||expanded_rs.t4_surface_moy||expanded_rs.t4_surface_max||expanded_rs.t4_loyer_min||expanded_rs.t4_loyer_moy||expanded_rs.t4_loyer_max">
											<td>{{ $t('t4') }}</td>
											<td v-if="expanded_rs.t4_lre_rse_nombre_logements">{{expanded_rs.t4_lre_rse_nombre_logements}}</td><td v-else>-</td>
											<td v-if="expanded_rs.t4_surface_min">{{expanded_rs.t4_surface_min}}</td><td v-else>-</td>
											<td v-if="expanded_rs.t4_surface_moy">{{expanded_rs.t4_surface_moy}}</td><td v-else>-</td>
											<td v-if="expanded_rs.t4_surface_max">{{expanded_rs.t4_surface_max}}</td><td v-else>-</td>
											<td v-if="expanded_rs.t4_loyer_min">{{expanded_rs.t4_loyer_min}}</td><td v-else>-</td>
											<td v-if="expanded_rs.t4_loyer_moy">{{expanded_rs.t4_loyer_moy}}</td><td v-else>-</td>
											<td v-if="expanded_rs.t4_loyer_max">{{expanded_rs.t4_loyer_max}}</td><td v-else>-</td>
										</tr>
										<tr v-if="expanded_rs.t5_lre_rse_nombre_logements||expanded_rs.t5_surface_min||expanded_rs.t5_surface_moy||expanded_rs.t5_surface_max||expanded_rs.t5_loyer_min||expanded_rs.t5_loyer_moy||expanded_rs.t5_loyer_max">
											<td>{{ $t('t5') }}</td>
											<td v-if="expanded_rs.t5_lre_rse_nombre_logements">{{expanded_rs.t5_lre_rse_nombre_logements}}</td><td v-else>-</td>
											<td v-if="expanded_rs.t5_surface_min">{{expanded_rs.t5_surface_min}}</td><td v-else>-</td>
											<td v-if="expanded_rs.t5_surface_moy">{{expanded_rs.t5_surface_moy}}</td><td v-else>-</td>
											<td v-if="expanded_rs.t5_surface_max">{{expanded_rs.t5_surface_max}}</td><td v-else>-</td>
											<td v-if="expanded_rs.t5_loyer_min">{{expanded_rs.t5_loyer_min}}</td><td v-else>-</td>
											<td v-if="expanded_rs.t5_loyer_moy">{{expanded_rs.t5_loyer_moy}}</td><td v-else>-</td>
											<td v-if="expanded_rs.t5_loyer_max">{{expanded_rs.t5_loyer_max}}</td><td v-else>-</td>
										</tr>
									</table>
								</div>
								<br/>
								
								<div class="program-detail-info-content"
									v-if ="expanded_rs.prix_mensuel_petit_dejeuner || expanded_rs.prix_mensuel_dejeuner || expanded_rs.prix_mensuel_diner || expanded_rs.prix_mensuel_demi_pension || expanded_rs.prix_mensuel_pension_complete
									|| expanded_rs.prix_jour_petit_dejeuner || expanded_rs.prix_jour_dejeuner || expanded_rs.prix_jour_diner || expanded_rs.prix_jour_demi_pension || expanded_rs.prix_jour_pension_complete
									|| expanded_rs.prix_annuel_petit_dejeuner || expanded_rs.prix_annuel_dejeuner || expanded_rs.prix_annuel_diner || expanded_rs.prix_annuel_demi_pension || expanded_rs.prix_annuel_pension_complete">
									<table>
										<tr><th>{{ $t('restauration') }}</th><th>{{ $t('prix') }}</th><th>{{ $t('temporalite') }}</th></tr>
										<tr v-if="expanded_rs.prix_mensuel_petit_dejeuner">
											<td>{{ $t('petit-dejeuner') }}</td>
											<td>{{expanded_rs.prix_mensuel_petit_dejeuner}} €</td>
											<td>{{ $t('mensuel') }}</td>
										</tr>
										<tr v-if="expanded_rs.prix_mensuel_dejeuner">
											<td>{{ $t('dejeuner') }}</td>
											<td>{{expanded_rs.prix_mensuel_dejeuner}} €</td>
											<td>{{ $t('mensuel') }}</td>
										</tr>
										<tr v-if="expanded_rs.prix_mensuel_diner">
											<td>{{ $t('diner') }}</td>
											<td>{{expanded_rs.prix_mensuel_diner}} €</td>
											<td>{{ $t('mensuel') }}</td>
										</tr>
										<tr v-if="expanded_rs.prix_mensuel_demi_pension">
											<td>{{ $t('demi-pension') }}</td>
											<td>{{expanded_rs.prix_mensuel_demi_pension}} €</td>
											<td>{{ $t('mensuel') }}</td>
										</tr>
										<tr v-if="expanded_rs.prix_mensuel_pension_complete">
											<td>{{ $t('pension_complete') }}</td>
											<td>{{expanded_rs.prix_mensuel_pension_complete}} €</td>
											<td>{{ $t('mensuel') }}</td>
										</tr>
										<tr v-if="expanded_rs.prix_jour_petit_dejeuner">
											<td>{{ $t('petit-dejeuner') }}</td>
											<td>{{expanded_rs.prix_jour_petit_dejeuner}} €</td>
											<td>{{ $t('jour') }}</td>
										</tr>
										<tr v-if="expanded_rs.prix_jour_dejeuner">
											<td>{{ $t('dejeuner') }}</td>
											<td>{{expanded_rs.prix_jour_dejeuner}} €</td>
											<td>{{ $t('jour') }}</td>
										</tr>
										<tr v-if="expanded_rs.prix_jour_diner">
											<td>{{ $t('diner') }}</td>
											<td>{{expanded_rs.prix_jour_diner}} €</td>
											<td>{{ $t('jour') }}</td>
										</tr>
										<tr v-if="expanded_rs.prix_jour_demi_pension">
											<td>{{ $t('demi-pension') }}</td>
											<td>{{expanded_rs.prix_jour_demi_pension}} €</td>
											<td>{{ $t('jour') }}</td>
										</tr>
										<tr v-if="expanded_rs.prix_jour_pension_complete">
											<td>{{ $t('pension_complete') }}</td>
											<td>{{expanded_rs.prix_jour_pension_complete}} €</td>
											<td>{{ $t('jour') }}</td>
										</tr>
										<tr v-if="expanded_rs.prix_annuel_petit_dejeuner">
											<td>{{ $t('petit-dejeuner') }}</td>
											<td>{{expanded_rs.prix_annuel_petit_dejeuner}} €</td>
											<td>{{$t('Annuel')}}</td>
										</tr>
										<tr v-if="expanded_rs.prix_annuel_dejeuner">
											<td>{{ $t('dejeuner') }}</td>
											<td>{{expanded_rs.prix_annuel_dejeuner}} €</td>
											<td>{{$t('Annuel')}}</td>
										</tr>
										<tr v-if="expanded_rs.prix_annuel_diner">
											<td>{{ $t('diner') }}</td>
											<td>{{expanded_rs.prix_annuel_diner}} €</td>
											<td>{{$t('Annuel')}}</td>
										</tr>
										<tr v-if="expanded_rs.prix_annuel_demi_pension">
											<td>{{ $t('demi-pension') }}</td>
											<td>{{expanded_rs.prix_annuel_demi_pension}} €</td>
											<td>{{$t('Annuel')}}</td>
										</tr>
										<tr v-if="expanded_rs.prix_annuel_pension_complete">
											<td>{{ $t('pension_complete') }}</td>
											<td>{{expanded_rs.prix_annuel_pension_complete}} €</td>
											<td>{{$t('Annuel')}}</td>
										</tr>
									</table>
								</div>
							</div>
							<div v-if="app.user.rs_option.includes('efocus-rs-option-loyer')">
								<div class="program-detail-info">
									<div class="program-detail-info-filters">
										<span class="filter first" @click="filter2 = 'base'; compteur=5; suffixe='T'" :class="{ active: filter2 == 'base' }">{{ $t('services_de_base') }}</span>
										<span class="filter last" @click="filter2 = 'complement'; compteur=12; suffixe='S'" :class="{ active: filter2 == 'complement' }">{{ $t('services_complementaires') }}</span>
									</div>
								</div>
								<div class="program-detail-info" v-if="filter2 == 'base'">
									<div class="program-detail-info-content" v-if="expanded_rs.services_base_communs_securite_intendance || expanded_rs.services_base_communs_loisirs || expanded_rs.services_base_communs_animations || expanded_rs.services_base_communs_prestations || expanded_rs.s5_srs_description">
										<table>
											<tr v-if="expanded_rs.services_base_communs_securite_intendance">
												<th id="cold1">{{ $t('securite_/_intendance') }}</th>
											</tr>
											<tr v-if="expanded_rs.services_base_communs_securite_intendance">
												<td class="center italique">{{expanded_rs.services_base_communs_securite_intendance}}</td>
											</tr>
											<tr v-if="expanded_rs.services_base_communs_loisirs">
												<th id="cold2">{{ $t('loisirs') }}</th>
											</tr>
											<tr v-if="expanded_rs.services_base_communs_loisirs">
												<td class="center italique">{{expanded_rs.services_base_communs_loisirs}}</td>
											</tr>
											<tr v-if="expanded_rs.services_base_communs_animations">
												<th id="cold3">{{ $t('animations') }}</th>
											</tr>
											<tr v-if="expanded_rs.services_base_communs_animations">
												<td class="center italique">{{expanded_rs.services_base_communs_animations}}</td>
											</tr>
											<tr v-if="expanded_rs.services_base_communs_prestations">
												<th id="cold5">{{ $t('prestations') }}</th>
											</tr>
											<tr v-if="expanded_rs.services_base_communs_prestations">
												<td class="center italique">{{expanded_rs.services_base_communs_prestations}}</td>
											</tr>
											<tr v-if="expanded_rs.s5_srs_description">
												<th id="cold6">{{ $t('autres') }}</th>
											</tr>
											<tr v-if="expanded_rs.s5_srs_description">
												<td class="center italique">{{expanded_rs.s5_srs_description}}</td>
											</tr>
										</table>
									</div>
								</div>
								<div class="program-detail-info" v-if="filter2 == 'complement'">
									<div class="program-detail-info-content" v-if="expanded_rs.services_complementaires_individuel_securite_intendance || expanded_rs.services_complementaires_individuel_loisirs || expanded_rs.services_complementaires_individuel_animations || expanded_rs.services_complementaires_individuel_prestations || expanded_rs.services_complementaires_individuel_autres">
										<table>
											<tr v-if="expanded_rs.services_complementaires_individuel_securite_intendance">
												<th id="colds1">{{ $t('securite_/_intendance') }}</th>
											</tr>
											<tr v-if="expanded_rs.services_complementaires_individuel_securite_intendance">
												<td class="center italique">{{expanded_rs.services_complementaires_individuel_securite_intendance}}</td>
											</tr>
											<tr v-if="expanded_rs.services_complementaires_individuel_loisirs">
												<th id="colds2">{{ $t('loisirs') }}</th>
											</tr>
											<tr v-if="expanded_rs.services_complementaires_individuel_loisirs">
												<td class="center italique">{{expanded_rs.services_complementaires_individuel_loisirs}}</td>
											</tr>
											<tr v-if="expanded_rs.services_complementaires_individuel_animations">
												<th id="colds3">{{ $t('animations') }}</th>
											</tr>
											<tr v-if="expanded_rs.services_complementaires_individuel_animations">
												<td class="center italique">{{expanded_rs.services_complementaires_individuel_animations}}</td>
											</tr>
											<tr v-if="expanded_rs.services_complementaires_individuel_prestations">
												<th id="colds5">{{ $t('prestations') }}</th>
											</tr>
											<tr v-if="expanded_rs.services_complementaires_individuel_prestations">
												<td class="center italique">{{expanded_rs.services_complementaires_individuel_prestations}}</td>
											</tr>
											<tr v-if="expanded_rs.services_complementaires_individuel_autres">
												<th id="colds7">{{ $t('autres') }}</th>
											</tr>
											<tr v-if="expanded_rs.services_complementaires_individuel_autres">
												<td class="center italique">{{expanded_rs.services_complementaires_individuel_autres}}</td>
											</tr>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>









				<div v-else>
						<div class="program-detail">
							<div class="program-detail-content">
								<div class="program-detail-info">
									<div class="program-detail-info-filters">
										<h1>{{ $t('les_evenements') }}</h1>
									</div>
									<br/>
									<Timeline :value="events2" align="bottom" layout="horizontal" class="customized-timeline">
										<template #marker="slotProps">
											<span>
												<i v-if="!slotProps.item.color" class="custom-marker" style="color:#333" :class="slotProps.item.icon"></i>
												<i v-else class="custom-marker" :class="slotProps.item.icon" :style="{backgroundColor: slotProps.item.color}"></i>
											</span>
										</template>
										<template #opposite="slotProps">
											<small class="p-text-secondary">{{AfficheDate(slotProps.item.date)}}</small>
										</template>
										<template #content="slotProps">
											{{slotProps.item.status}}
										</template>
									</Timeline>
									
									<div class="surface-card border-round p-4" id="insee_rs_chiffres_img" style="background: #f6f6f6!important; width: calc(100% - 7px);">
										<div class="flex align-items-start mb-5">
											<div class="ml-3" style="text-align: left;">
												<span class="block text-900 mb-1 text-xl font-medium">{{ $t('permis_de_construire') }}</span>
												<p class="text-600 mt-0 mb-0"></p>
											</div>
										</div>
										<ul class="list-none p-0 m-0">
											
											<li class="mb-5" v-if="expanded_rs.tre_libelle">
												<div style="display:flex">
													<div style="width:100%">
														<div class="flex justify-content-between align-items-center">
															<span class="text-900 inline-flex justify-content-between align-items-center">
																<i class="pi pi-building	 mr-2" style="color:var(--primary-500) !important"></i>
																<span class="font-medium text-900">{{ $t('Type de résidence') }}</span>
															</span>
															<span class="text-purple-600 font-medium">{{expanded_rs.tre_libelle}}<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span>
														</div>
													</div>
												</div>
											</li>

											<li class="mb-5" v-if="expanded_rs.nopc">
												<div style="display:flex">
													<div style="width:100%">
														<div class="flex justify-content-between align-items-center">
															<span class="text-900 inline-flex justify-content-between align-items-center">
																<i class="pi pi-hashtag mr-2" style="color:var(--primary-500) !important"></i>
																<span class="font-medium text-900">{{ $t('numero_de_permis_de_construire') }}</span>
															</span>
															<span class="text-purple-600 font-medium">{{expanded_rs.nopc}}<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span>
														</div>
													</div>
												</div>
											</li>
											<li class="mb-5">
												<div style="display:flex">
													<div style="width:100%">
														<div class="flex justify-content-between align-items-center">
															<span class="text-900 inline-flex justify-content-between align-items-center">
																<i class="pi pi-user mr-2" style="color:var(--primary-500) !important"></i>
																<span class="font-medium text-900" v-if="expanded_rs.promoteur">{{expanded_rs.promoteur}}</span><span class="font-medium text-900" v-else>{{ $t('n.c.') }}</span>
															</span>
															<span class="text-purple-600 font-medium" v-if="expanded_rs.nature_depositaire">{{expanded_rs.nature_depositaire}}<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span>
														</div>
													</div>
												</div>
											</li>
											<li class="mb-5">
												<div style="display:flex">
													<div style="width:100%">
														<div class="flex justify-content-between align-items-center">
															<span class="text-900 inline-flex justify-content-between align-items-center">
																<i class="pi pi-map mr-2" style="color:var(--primary-500) !important"></i>
																<span class="font-medium text-900">{{ expanded_rs.adresse }} | {{ expanded_rs.codepostal }} {{ expanded_rs.ville }}</span>
															</span>
															<span  v-if="expanded_rs && expanded_rs.location && expanded_rs.location.lat" class="text-purple-600 font-medium"><span><a target="_blank" :href="'https://www.google.com/maps/@?api=1&map_action=pano&viewpoint=' + expanded_rs.location.lat + ',' + expanded_rs.location.lon"><img alt="streetview" src="https://www.myadequation.fr/EFOCUS/streetview.png"/>&nbsp;{{ $t('voir_sur_google_street_view') }}</a><span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span></span>
														</div>
													</div>
												</div>
											</li>
											<li class="mb-5">
												<div style="display:flex">
													<div style="width:100%">
														<div class="flex justify-content-between align-items-center">
															<span class="text-900 inline-flex justify-content-between align-items-center">
																<div class="forme-urbaine" v-if="expanded_rs && expanded_rs.forme_urbaine && expanded_rs.forme_urbaine.toUpperCase()=='COLLECTIF'"><icon file="building" :size="16" style="color: var(--primary-500) !important" /></div>
																<div class="forme-urbaine" v-else><icon file="home" :size="16" style="color:var(--primary-500) !important" /></div>
																<span class="font-medium text-900">&nbsp;&nbsp;{{expanded_rs.nb_log_pc}} {{ $t('logement') }}<span v-if="expanded_rs.nb_log_pc>1">s</span> {{ $t('toutes_destinations_confondues') }}</span>
															</span>
															<span class="text-purple-600 font-medium" v-if="expanded_rs.pdc_superficie">{{ $t('sur_un_terrain_de') }} {{expanded_rs.pdc_superficie}} m²<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span></span>
														</div>
													</div>
												</div>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
















<!--
				<div v-else class="program" :class="{ inactif: expanded_rs.commercialisation == 'termine', selected: expanded_rs.selected }">
					<div class="program-main" style="height:285px;">
						
						<div class="image">
							<img :src="expanded_rs.url_photo" alt="">
						</div>
					</div>
					<div class="program-main">
						<div class="program-content">
							<p class="name" style="text-align: center;"><b><span class="nom">{{ expanded_rs.nom }}</span></b><span v-if="expanded_rs.promoteur"> | {{ expanded_rs.promoteur }}</span> | {{ expanded_rs.adresse }} | {{ expanded_rs.codepostal }} {{ expanded_rs.ville }} 
								<span v-if="!expanded_rs.avant_premiere&&!expanded_rs.pc">| {{ expanded_rs.stock_initial }} logements </span> | {{ expanded_rs.forme_urbaine }}
							</p>
						</div>
					</div>
					<div v-if="expanded_rs.pc">
						<div class="program-detail">
							<div class="program-detail-content">
								<div class="program-detail-info">
									<div class="program-detail-info-content">
										<table>
											<th><td></td><td></td><td></td></th>
											<tr>
												<td>{{ $t('nature_du__depositaire') }}</td>
												<td style="text-align: right" colspan="2" v-if="expanded_rs.nature_depositaire!=''">{{expanded_rs.nature_depositaire}}</td>
												<td style="text-align: right" colspan="2" v-else>{{ $t('non_determine_(valeur_par_defaut_a_la_creation_du_permis)') }}</td>
											</tr>
											<tr>
												<td colspan="2">{{ $t('nombre_de_logement_toutes_destinations_confondues') }}</td>
												<td style="text-align: right">{{expanded_rs.nb_log_pc}}</td>
											</tr>
											<tr>
												<td>{{ $t('superficie') }}</td>
												<td style="text-align: right" colspan="2">{{expanded_rs.pdc_superficie}} <span v-if="expanded_rs.pdc_superficie"> m²</span></td>
											</tr>
											<tr>
												<td>{{ $t('parcelles_cadastrales') }}</td>
												<td style="text-align: right" colspan="2">{{expanded_rs.pdc_num_parcelle_cadastrale_1}}
													<span v-if="expanded_rs.pdc_num_parcelle_cadastrale_2"> | {{expanded_rs.pdc_num_parcelle_cadastrale_2}}</span>
													<span v-if="expanded_rs.pdc_num_parcelle_cadastrale_3"> | {{expanded_rs.pdc_num_parcelle_cadastrale_3}}</span>
												</td>
											</tr>
										</table>
									</div>
									<br/>
									<div class="program-detail-info-filters">
										<span class="filter first" :class="{ active: true, last : true }">{{ $t('evenements') }}</span>
									</div>
									<br/>
									<div class="program-detail-info-content">
										<table>
											<th><td></td><td></td></th>
											<tr v-for="evt in expanded_rs.evt" v-bind:key="evt.id">
												<td>{{evt.libelle}}</td>
												<td>{{evt.dates}}</td>
											</tr>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
-->
			</div>
		</div>
	</transition>
</template>

<script>
import { mapGetters } from 'vuex'
import Rating from 'primevue/rating';
import Button from 'primevue/button';
import Timeline from 'primevue/timeline';

export default {
	name: 'PaneRs',

	data: function() {
		return {
			expanded: false,
			tva: 'tva_pleine_libre',
			pkg: 'prix_sp',
			filter: 'volume',
			filter2: 'base',
			suffixe: 'T',
			filter3: 'tva_pleine_libre_sans',
			filter4: 'global',
			filter_svo: 's',
			mode_aff: 'granulaire',
			mode_aff_histo: 'programme',
			compteur: 5
		}
	},

	computed: Object.assign(mapGetters([ 'app', 'expanded_rs', 'selected_rs_secteurs' ]), {

		events2: function() {
			let t = []
			if (this.expanded_rs.evt) {
				for (let index = 0; index < this.expanded_rs.evt.length; index++) {
					let lib = ''
					let icon = ''
					switch(this.expanded_rs.evt[index].libelle)
					{
						case 'permis autorisé':
							lib = 'Autorisé'
							icon = 'pi pi-thumbs-up'
							break;
						case 'permis déposé':
							lib = 'Déposé'
							icon = 'pi pi-inbox'
							break;
						case 'permis non déposé':
							lib = 'Non déposé'
							icon = 'pi pi-eye-slash'
							break;
						case 'permis en recours':
							lib = 'En recours'
							icon = 'pi pi-megaphone'
							break;
						case 'permis en instruction':
							lib = 'En instruction'
							icon = 'pi pi-clock'
							break;
						case 'permis en sursis à statuer':
							lib = 'En sursis à statuer'
							icon = 'pi pi-exclamation-triangle'
							break;
						case 'permis annulé':
							lib = 'Annulé'
							icon = 'pi pi-ban'
							break;
						default:
							lib = this.expanded_rs.evt[index].libelle;
							icon = 'pi pi-check'
					}
					t.push({status: lib, date: this.expanded_rs.evt[index].dates, icon: icon, color: '#22c55e'})
				}
				t.push({status: '', date: '', icon: '', color: ''})
				t.sort((a, b) => {
					if (a.date < b.date) {
						return -1;
					}
					if (a.date > b.date) {
						return 1;
					}
					return 0;
				});
				t.push({status: '', date: '', icon: '', color: ''})
			}
			return t
		},

		
	}),

	watch: {
		'app.expanded_rs': function(newValue) {
			if (newValue) {
				this.filter_svo = "s"
				
				if (this.expanded_rs.tva_reduite_prix_m2_parking_avec + this.expanded_rs.tva_reduite_prix_m2_parking_sans > 0) {
					this.filter3 = 'tva_reduite';
					this.tva = 'tva_reduite';
				} else {
					if (this.expanded_rs.tva_pleine_libre_prix_m2_parking_avec + this.expanded_rs.tva_pleine_libre_prix_m2_parking_sans > 0) {
						this.filter3 = 'tva_pleine_libre';
						this.tva = 'tva_pleine_libre';
					} else {
						this.filter3 = 'tva_pleine_aide';
						this.tva = 'tva_pleine_aide';
					}
				}
				
				if (this.expanded_rs.tva_pleine_libre_prix_m2_parking_avec + this.expanded_rs.tva_pleine_aide_prix_m2_parking_avec + this.expanded_rs.tva_reduite_prix_m2_parking_avec>0) {
					this.pkg = 'prix_ap'; 
				} else {
					this.pkg = 'prix_sp';
				}
			}
		},
	},
	
	methods: {
		generateRsImage(id) {
			document.getElementById("pan-titre-rs").style.visibility = 'hidden';
			this.$store.dispatch('ExportToPng', {id: 'RsEx', name: 'FicheResidenceService_' + id});
			document.getElementById("pan-titre-rs").style.visibility = 'visible';
		},

		getTxtTaux: function(value) {
			switch (value) {
				case 'Faible':
					return '< 70 %'
				case 'Moyen':
					return '70 - 90 %'
				case 'Fort':
					return 'plus de 90 %'
				default:
					return value
			}
		},
		
		getTxtTauxClass: function(value) {
			if (value < 0.7) return 'Faible'
			else {
				if (value < 0.9) return 'Moyen'
				else return 'Fort'
			}
		},

		getTxtClass: function(value) {
			switch (value) {
				case '< 70%':
					return 'Faible'
				case '70 - 90%':
					return 'Moyen'
				case '> 90%':
					return 'Fort'
				default:
					return ''
			}
		},		

		expand_rs: function() {
			this.$router.push({ name: 'rs'}).catch(()=>{});
			if (this.expanded_rs != this.rs) {
				this.$store.dispatch('expand_rs', { rs: this.rs })
			} else {
				this.$store.dispatch('expand_rs', { rs: false })
			}
		},

		AfficheDate(dateString){
			if (dateString) {
				const parts = dateString.split('-');
				return `${parts[2]}/${parts[1]}/${parts[0]}`;
			} else return '';
		},

		print: function() {
			this.$store.dispatch('print_program', { divname: "rsencours"})
			this.$store.dispatch('SendEvent', { action: "program_download", opt_label: this.expanded_rs.nom, opt_value: 0 })
			this.$store.dispatch('set_print', { print: 'expanded-program' })
		}
	},

	// eslint-disable-next-line
	components: { Rating, Button, Timeline }
}
</script>

<style lang="stylus" scoped>
@import '../assets/css/app'

.pane-program-enter, .pane-program-leave-to
	transform translateX(100%)

.pan-titre-programme
	z-index 999
	position fixed
	float left
	with 100%
	transition 0.5s easeOutQuart
	.accordion-menu
		overflow hidden scroll
		height 100%
.fermer
	position absolute
	width 60px
	right 0px
	border-left 1px solid palette_gris_bordure
	text-align center
	line-height 60px
	cursor pointer
.aide
	width 60px
	left 60px
	position absolute
	border-right 1px solid palette_gris_bordure
	text-align center
	line-height 60px
	cursor pointer
.chatbox
	width 60px
	left 0px
	border-right 1px solid palette_gris_bordure
	position absolute
	text-align center
	line-height 60px
	cursor pointer
.print
	width 60px;
	position absolute
	right 60px
	border-left 1px solid palette_gris_bordure
	text-align center
	line-height 60px
	cursor pointer

.module_ret
	width 800px
	height 60px
	vertical-align middle
	background-color palette_gris
	border-bottom 1px solid palette_gris_bordure
	border-right 1px solid palette_gris_bordure

.pane-program
	position absolute
	right 0
	top headersize
	bottom 0
	height calc(100% - 120px)
	width 800px
	background-color #fefefe
	transition 0.5s easeOutQuart
	z-index 599
	border-left 1px solid palette_gris_bordure

.program
	width 100%
	border-bottom 2px solid #eee
	&.inactif
		.program-main
			background #f5f5f5
			.program-content
				.name
					color #666
	&.expanded
		&:hover
			.program-main
				background lighten(#e3f2fd, 25%)
		.program-main
			background lighten(#e3f2fd, 50%)
			box-shadow inset -2px 2px 0 0 palette2, inset 2px 2px 0 0 palette2
			.program-content
				.name
					color palette2
		.program-detail
			box-shadow inset -2px -2px 0 0 palette2, inset 2px -2px 0 0 palette2
	&.	
		&:hover
			.program-main
				background lighten(orange, 85%)
		.program-main
			background lighten(orange, 90%)
	&.disabled, &.disabled:hover
		.program-main
			box-shadow none
			.image
				background #eee
				img
					display none
			.price
				color lighten(green, 10%)
			.program-content
				.name, .address, .city, .seller
					color #fff
					&:after
						content ''
						position absolute
						left 0
						top 4px
						bottom 4px
						width 75%
						background #eee
				.forme-urbaine
					color #fff
					&:after
						content ''
						position absolute
						left 0
						top 0
						bottom 0
						width 100%
						background #eee
						border-radius radius
		.program-detail
			display none
	.program-main
		background #fefefe
		padding 16px
		.image
			position absolute
			left 200px
			top 16px
			height 285px
			width 396px
			border-radius radius
			background #eee url(../assets/img/no-camera.png) center center no-repeat
			background-size 48px 48px
			img
				position absolute
				left 50%
				transform translateX(-50%)
				height 100%
				width auto
		.program-content
			margin-left 16px
			.name
				height 24px
				line-height 20px
				text-align center
			.city, .address
				color #999
				font-size 12px
			.seller
				margin 8px 0 0 0
				text-transform uppercase
				font-size 12px
				line-height 24px
			.forme-urbaine
				position absolute
				right 0
				bottom 0
				color #999
		.price
			position absolute
			left 44px
			top 24px
			padding 0 8px 0 16px
			font-family volte, sans-serif
			font-weight 600
			line-height 24px
			background lighten(green, 10%)
			color #fff
			&:after, &:before
				content ''
				position absolute
				right -16px
				width 0
				height 0
				border-style solid
			&:before
				top 0
				border-width 16px 16px 0 0
				border-color lighten(green, 10%) transparent transparent transparent
			&:after
				bottom 0
				border-width 16px 0 0 16px
				border-color transparent transparent transparent lighten(green, 10%)
			.angle
				position absolute
				left 0
				top -4px
				width 0
				height 0
				border-style solid
				border-width 0 0 4px 6px
				border-color transparent transparent darken(blue, 50%) transparent

.program-detail
	overflow hidden
	height auto
	&.detail-enter, &.detail-leave-to
		height 430px
		background-color red
	.program-detail-content
		padding 16px
		.resume
			margin 8px 0 0 0
			&:first-child
				margin 0
			&:after
				content ''
				display block
				clear both
			.resume-data
				float left
				width 33.3333%
				margin 0 0 8px 0
				.label
					font-size 10px
					text-transform uppercase
					color #999
				.value
					font-weight 500
		.actions
			margin 8px 0 0 0
			.action
				display inline-block
				margin 0 24px 0 0
				height 32px
				color #666
				font-size 12px
				font-weight 500
				line-height @height
				cursor pointer
				&:hover
					color #333
				.icon
					float left
					margin 4px 8px 0 0
		.program-detail-info
			padding 8px 0
			.program-detail-info-filters
				text-align center
				margin 0 0 8px 0
			.program-detail-info-content
				padding 8px 0
				background #f5f5f5
				border-radius radius
				overflow hidden
				table
					width 100%
					border-collapse collapse
					tr
						&:last-child
							border none
						&:hover
							td
						td, th
							padding 4px 8px
						th
							font-size 12px
							font-weight 500
						td
							text-align center
							&:first-child
								text-align left
								font-size 12px
								font-weight bold
								padding-left 16px

.actionaide
	position absolute
	right 140px
	top 0px
	z-index 999
.actionfermer
	position absolute
	right 22px
	top 0px
	z-index 999
.action_print
	position absolute
	right 60px
	top 0px
	z-index 999
.action_help
	position absolute
	right 100px
	top 0px
	z-index 999


.indice_confiance
	position absolute
	right 121px
	width 620px !important
	height 60px
	top 0px
	z-index 999
	vertical-align middle
	line-height 59px
	text-align center
	font-size 14px
	&.bas
		background qualitedonneesbas
	&.milieu
		background qualitedonneesmilieu
	&.haut
		background qualitedonneeshaut
	&.pc
		background #ccc

.vb
	.vb-dragger
		z-index 5
		width 10px
		right 0

.back-button
	cursor pointer
	border-radius 100%
	transition 0.25s easeOutQuart
	width 32px
	height 32px
	padding 4px	
	&:hover
		color #fff
		background-color darken(green, 10%)

.program-maj
	text-align center
	font-size 12px
	font-weight 500
	padding-left 16px
	color palette1

.nom
	color primaryA100

.cible
	color #000

.refreshprog
	height 185px
	width 750px

.infinite-loader
	position absolute
	right 307px
	top 28px
	.loader
		display inline-block
		background main_color
		border-radius 100%
		color #fff
		.icon
			display block
			animation rotate 1.5s infinite linear

.Faible
	color #f2131f
	font-weight 600
.Moyen
	color #f2aa1f
	font-weight 600
.Fort
	color #1eaa1f
	font-weight 600
.recent
	color #1eaa1f
	font-weight 600

.center
	text-align center !important


.custom-marker {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	width: 2rem;
	height: 2rem;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	color: #fff;
	border-radius: 50%;
	z-index: 1;
}
@media print
	.program
		.program-detail
			.program-detail-content
				.actions
					display none


</style>
