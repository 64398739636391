<template>
	<div class="stat-box">
		<div class="stat-box-content">
			<div class="row">
				<div class="col full">
					<span class="block text-900 mb-1 text-xl font-medium">{{ $t('Zoom sur les résidences services étudiantes') }}</span>
				</div>
			</div>
			<div class="row">
				<div class="col half">
					<div class="graph">
						<div id="mapVilleEtudiant" class="mapVilleEtudiant"></div>
					</div>
					<div class="font-medium text-900 pt-3 pb-3">{{$t('Aide à la sélection du territoire')}}</div>
					<div class="field-radiobutton">
						<RadioButton id="r_prjt-etudiant" name="sel-etudiant" value="prjt" v-model="typ_select" />
						<label for="r_prjt-etudiant">{{$t('La commune de votre projet')}}</label>
					</div>
					<div class="field-radiobutton" v-if="this.app.livedata.synoptique_rs.length">
						<RadioButton id="r_resi-etudiant" name="sel-etudiant" value="resi" v-model="typ_select" />
						<label for="r_resi-etudiant">{{$t('Sélectionner à partir de votre sélection de résidences')}}</label>
					</div>
					<div class="field-radiobutton">
						<RadioButton id="r_epci-etudiant" name="sel-etudiant" value="epci" v-model="typ_select" />
						<label for="r_epci-etudiant">{{$t('Sélectionner les communes de l\'EPCI de votre projet')}} ({{ this.app.adr_init.epci_label }})</label>
					</div>
					<div class="field-radiobutton">
						<RadioButton id="r_epcis-etudiant" name="sel-etudiant" value="epcis" v-model="typ_select" />
						<label for="r_epcis-etudiant">{{$t('Sélectionner les communes des EPCIs sélectionnés')}}</label>
					</div>

					
					<div style="width: 100%;display: flex;align-items: center;flex-direction: row;flex-wrap: wrap;justify-content: flex-start;">
						<Chip style="margin-bottom:5px;margin-right:5px;" v-for="(secteur, index) in epcis_label" v-bind:key="secteur" :label="epcis_label[index]" removable @remove="del_epci(index);" />
					</div>
					<div class="p-dropdown-filter-container mt-2" v-show="typ_select=='epcis'" style="padding-bottom:10px;">

						<div class="card">
							<InputText ref="ajs-etudiant" id="ajs-etudiant" type="text" v-model="search" @input="filterData" class="w-full mb-2"/>
							<DataTable :value="paginatedData" :rows="rows" :totalRecords="totalRecords" :lazy="true" @lazy-load="loadData" selectionMode="multiple" :selection.sync="selectedRows" :metaKeySelection="false">
								<Column selectionMode="multiple"></Column>
								<Column field="epc_code" :header="$t('Code')"></Column>
								<Column field="epc_libelle" :header="$t('Libellé')"></Column>
								<template #footer>
									<div style="display: flex;justify-content: flex-end">
										<Button label="Ajouter" icon="pi pi-check" class="p-button-sm" @click="AjouterEpci()" />
									</div>
								</template>
							</DataTable>
						</div>
					</div>


					<div class="field-radiobutton">
						<RadioButton id="r_dist-etudiant" name="sel-etudiant" value="dist" v-model="typ_select" />
						<label for="r_dist-etudiant">{{$t('Sélectionner les communes en fonction d\'une distance')}}</label>
						
					</div>
					<div v-if="typ_select=='dist'" style="display:flex; width:100%;justify-content: space-around;padding-top:10px;padding-bottom:10px">
						<Slider v-model="rayon" :step="100" :max="20000" style="width:calc(100% - 100px)" />
						<div style="top:-10px">{{ rayon }} m</div>
					</div>
					<div v-else style="height:41px;">&nbsp;</div>
					<div class="app_filtre" style="width:200px" @click="valide_select()">{{ $t('Appliquer la sélection') }}</div>					
				</div>
				<div class="col half">
					<div class="shadow-2 surface-card border-round p-4" id="insee_rs_chiffres_img" style="background: #f6f6f6!important">
						<div class="flex align-items-start mb-5">
							<div class="ml-3" style="text-align: left;">
								<span class="block text-900 mb-1 text-xl font-medium">{{ $t('Taux de structuration du marché des résidences services étudiantes libre') }}</span>
								<p class="text-600 mt-0 mb-0">{{ $t('Sélection des communes sur la carte') }}</p>
							</div>
						</div>
						<ul class="list-none p-0 m-0">
							<div style="padding-bottom: 20px">
								<MultiSelect v-model="selectedLabelCommunes" :options="selectedLabelCommunes" optionLabel="label" display="chip" />
							</div>
							<li class="mb-5">
								<div class="flex justify-content-between align-items-center">
									<span class="text-900 inline-flex justify-content-between align-items-center">
										<i class="pi pi-user-plus mr-2"></i>
										<span class="font-medium text-900">{{ $t('des résidences services étudiantes') }}</span>
									</span>
									<span v-if="!calcencours" :class="getClassTx()">{{ TxStructuration }} %</span><span v-else><div class="loader">
								<icon file="loader" :size="32" style="color:#9C27B0" />
							</div></span>
								</div>
								<div class="surface-300 w-full mt-2" style="height: 7px; border-radius: 4px">
									<div class="bg-teal-500" style="height: 7px; border-radius: 4px" :style="{ width : TxStructuration + '%'}"></div>
								</div>
							</li>
						</ul>
						<ul>
							<li class="mb-5">
								<div class="flex justify-content-between align-items-center">
									<div>
										<span class="text-xl font-medium text-900">{{ NbResSenior }}</span> {{$t('Résidence')}}<span v-if="NbResSenior>1">s</span>
									</div>
									<div>
										<span class="text-xl font-medium text-900">{{ NbLogSenior }}</span> {{$t('logement')}}<span v-if="NbLogSenior>1">s</span> {{$t('étudiant')}}<span v-if="NbLogSenior>1">s</span>
									</div>
									<div>
										<span class="text-xl font-medium text-900">{{ NbEtudiant }}</span> {{$t('étudiant')}}<span v-if="NbEtudiant>1">s</span>
									</div>
								</div>
							</li>
						</ul>
					</div>
					<br>
					<div class="shadow-2 surface-card border-round p-4" id="insee_rs_chiffres_img" style="background: #f6f6f6!important">
						<div class="flex align-items-start mb-5">
							<div class="ml-3" style="text-align: left;">
								<p class="text-600 mt-0 mb-0">{{ $t('Le Taux de structuration du marché représente le nombre de logements en résidences services étudiantes libre en exploitation pour 100 étudiants') }}</p>
								<p class="text-600 mt-0 mb-0">{{ $t('Formule : Nombre de logements en RSE libre en exploitation présentes sur les territoires selectionnées / Nombre d\'étudiants * 100') }}</p>
							</div>
						</div>
						<ul class="list-none p-0 m-0">
							<li class="mb-5">
								<div class="flex justify-content-between align-items-center">
									<span class="text-900 inline-flex justify-content-between align-items-center">
										<span class="font-medium text-900">{{ $t('Si le taux est inférieur à 3% alors le territoire') }} </span>
									</span>
									<span class="text-blue-600 font-medium">{{ $t('est en développement') }}</span>
								</div>
							</li>
							<li class="mb-5">
								<div class="flex justify-content-between align-items-center">
									<span class="text-900 inline-flex justify-content-between align-items-center">
										<span class="font-medium text-900">{{ $t('Si le taux est entre 3% et 8% alors le territoire') }} </span>
									</span>
									<span class="text-teal-600 font-medium">{{ $t('est structuré') }}</span>
								</div>
							</li>
							<li class="mb-5">
								<div class="flex justify-content-between align-items-center">
									<span class="text-900 inline-flex justify-content-between align-items-center">
										<span class="font-medium text-900">{{ $t('Si le taux de inférieur > à 8% alors le territoire') }} </span>
									</span>
									<span class="text-red-600 font-medium">{{ $t('est concurrenciel') }}</span>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import L from 'leaflet'
import leafletLasso from 'leaflet-lasso' /* eslint-disable-line */
import axios from 'axios'
// eslint-disable-next-line
import Chip from 'primevue/chip';
import MultiSelect from 'primevue/multiselect';
import RadioButton from 'primevue/radiobutton';
import Slider from 'primevue/slider';

import InputText from 'primevue/inputtext';
// eslint-disable-next-line
import {FilterMatchMode,FilterOperator} from 'primevue/api/';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';

export default {
	name: 'ArrayInseeTxStructurationEtudiant',

	data: function() {
		return {
			epcis_label: [],
			epcis_code: [],
            epcis: [
            ],
			filteredData: [],
			lazyParams: { first: 0, rows: 10 },
			search: '',
			paginatedData: [],
            totalRecords: 0,
            rows: 5,
			selectedRows: [],

			typ_select: 'prjt',
			rayon: 2000,
			codeinsee: false,
			InseeInvisible: ['75056', '69123', '13055'],
			selectedCommunes: [],
			communesLayers: {},
			communesdata: {},
			LayerMapnik: false,
			mapVilleEtudiant: false,
			TxStructuration: 0,
			NbMenSenior : 0,
			NbLogSenior : 0,
			NbResSenior: 0,
			calcencours: false,
			sel_style : {
				weight: 2, 
				color: "#ff9900", 
				fillColor: "#ff9900",
				fillOpacity: 0.3,
			},
			vil_style : {
				weight: 2, 
				color: "#888888", 
				fillColor: "#F4EDFF",
				fillOpacity: 0.2,
			},
			wkt_str:'',
		}
	},

	computed: Object.assign(mapGetters([ 'app' ]), {
		selectedLabelCommunes : function() {
			let s = [];
			for (let index = 0; index < this.selectedCommunes.length; index++) {
				s.push({label : this.getCommuneName(this.selectedCommunes[index])})
			}
			return s;
		},

		EpciSelect: function() {
			return this.epcis_label.join(', ');
		},
		NbEtudiant: function() {
			return this.NbMenSenior
		}
		
	}),
	
	mounted: function() {
        window.addEventListener('keydown', this.onKeydown, { passive: true });
		let accessToken = 'pk.eyJ1Ijoic2ViZHVwb250IiwiYSI6ImNrdmdlcDMxaTAxdmUydXAzcW5yNDRpZmQifQ.8AvKt1ETauEmOWnK0TjNSg';
		this.LayerMapnik = L.tileLayer('https://api.mapbox.com/styles/v1/sebdupont/cl8x860c1000l15qi6h68eupp/tiles/512/{z}/{x}/{y}@2x?access_token=' + accessToken, {
				attribution: '© <a target="_blank" href="https://www.mapbox.com/feedback/">Mapbox</a> © <a target="_blank" href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>',
				tileSize: 512,
				zoomOffset: -1
			});

			let minzoom = 12;
			if (this.app.parcours_perso_rs.includes(this.app.efocus_type)) minzoom = 10
		this.mapVilleEtudiant = L.map('mapVilleEtudiant', {
			center: [this.app.mrk.lat, this.app.mrk.lng],
			attribution: '<a target="_blank" href="https://leaflet.js/">Leaflet</a> ©',
			zoom: 13,
			minZoom: minzoom,
			zoomControl: true,
			layers: [ this.LayerMapnik ]
		})

		this.mapVilleEtudiant.on('moveend', () => this.updateCommunes(this.selectedCommunes, false));
		this.mapVilleEtudiant.on('zoomend', () => this.updateCommunes(this.selectedCommunes, false));


		var that = this;
		//const url = this.app.BaseUrlBackend + '/insee/getEpcis';
		//axios.get(url, { headers: this.app.HeaderEfocusBack })




		const bounds = this.mapVilleEtudiant.getBounds();

		// Récupérer les coordonnées des coins sud-ouest et nord-est
		const southWest = bounds.getSouthWest();
		const northEast = bounds.getNorthEast();

		// Calculer la largeur et la hauteur actuelles
		const width = northEast.lng - southWest.lng;
		const height = northEast.lat - southWest.lat;

		// Calculer les nouvelles coordonnées
		const newSouthWest = L.latLng(southWest.lat - height / 2, southWest.lng - width / 2);
		const newNorthEast = L.latLng(northEast.lat + height / 2, northEast.lng + width / 2);

		// Créer la nouvelle bounding box
		const newBounds = L.latLngBounds(newSouthWest, newNorthEast);

		// Obtenir les coordonnées en tant que chaîne de texte
		const newBBoxString = newBounds.toBBoxString();


//		let url = 'https://app.myadequation.fr/map/?service=WFS&version=1.0.0&request=GetFeature&typeName=Web:epci&maxFeatures=100000&bbox=' + this.mapVille.getBounds().toBBoxString() + '&outputFormat=application/json'
		let url = 'https://app.myadequation.fr/map/?service=WFS&version=1.0.0&request=GetFeature&typeName=Web:epci&maxFeatures=100000&bbox=' + newBBoxString + '&outputFormat=application/json'
		axios.get(url)
		.then(function(response) {
			that.epcis = []
			for (let index = 0; index < response.data.features.length; index++) {
				const epci = response.data.features[index].properties;
				that.epcis.push({epc_libelle: epci.epci, epc_code: epci.siren})
			}
			that.epcis.sort((a, b) => {
            const nameA = a.epc_libelle.toLowerCase();
            const nameB = b.epc_libelle.toLowerCase();
            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;
            return 0;
			});
			that.filteredData = that.epcis;
			that.totalRecords = that.epcis.length;
			that.filterData()
			that.loadData(that.lazyParams);
		})



		const selectedInseeCodes = []
		if (this.app.parcours_perso_rs.includes(this.app.efocus_type)) {
			let that = this
			const centreCercle = [this.app.mrk.lat, this.app.mrk.lng]; // Coordonnées de Paris, par exemple
			const rayonCercle = 20000; // Rayon en mètres
			let a = L.featureGroup().addTo(this.mapVilleEtudiant)
			L.circle(centreCercle, {
				color: 'red',      // Couleur de la bordure du cercle
				fillColor: '#f03', // Couleur de remplissage
				fillOpacity: 0.5,  // Opacité du remplissage
				radius: rayonCercle // Rayon du cercle en mètres
			}).addTo(a)
			this.mapVilleEtudiant.fitBounds(a.getBounds());
			a.clearLayers()

			let UneUrl = 'https://app.myadequation.fr/map/?service=WFS&version=1.0.0&request=GetFeature&typeName=Web%3Acommune&cql_filter=DWITHIN(geom,%20POINT%20(' + this.app.mrk.lng + '%20' + this.app.mrk.lat + '),20,kilometers)&propertyName=insee&maxFeatures=500&outputFormat=application%2Fjson'
			fetch(UneUrl)
			.then(response => response.json())
			.then(data => {
				data.features.forEach(feature => {
					selectedInseeCodes.push(feature.properties.insee)
					that.codeinsee = feature.properties.insee;
				})
				that.updateCommunes(selectedInseeCodes, true);
				}
			)
		} else {
			selectedInseeCodes.push(this.app.insee[0].insee_code)
			this.codeinsee = this.app.insee[0].insee_code;
			this.communesdata[this.codeinsee] = this.app.insee[0].sector_name;
			this.updateCommunes(selectedInseeCodes, true);
		}

	},
	watch: {
		'app.mrktime': function(newValue) {
			if (newValue) {
				this.center_map()
			}
		},
	},
	methods: {
		del_epci(index) {
			this.epcis_code.splice(index,1)
			this.epcis_label.splice(index,1)
		},

		AjouterEpci() {
            for (let index = 0; index < this.selectedRows.length; index++) {
                const element = this.selectedRows[index];
				if (this.epcis_code.indexOf(element.epc_code) < 0) {					
					this.epcis_code.push(element.epc_code)
					this.epcis_label.push(element.epc_libelle)
				}
            }
            this.search='';
            this.paginatedData = [];
			this.selectedRows = [];
        },

        loadData(event) {
            this.lazyParams = event;
            let data = this.filteredData.slice(event.first, event.first + event.rows);
//            if (this.search!='') this.paginatedData = data
//            else this.paginatedData = []
            this.paginatedData = data
        },

        filterData() {
            if (this.search) {
                this.filteredData = this.epcis.filter(epci => {
                    const matchesSearch = epci.epc_libelle.toLowerCase().includes(this.search.toLowerCase());
                    return matchesSearch;
                    }
                );
            } else {
                this.filteredData = this.epcis;
            }

            this.totalRecords = this.filteredData.length;
            this.loadData(this.lazyParams);
        },

        onKeydown(event) {
            if (event.key === 'Escape') {
                this.searchQuery='';
                this.filteredData = [];
            }
        },

		raz_select() {
			const A = [];
			for (let index = 0; index < this.selectedCommunes.length; index++) {
				A.push(this.selectedCommunes[index])
			}

			for (let index = 0; index < A.length; index++) {
				const communeId = A[index];
				this.toggleCommuneSelection(communeId,false);
			}
		}, 

		raz_epci() {
			this.epcis_code = []
			this.epcis_label = []
		},

		valide_select() {
			this.raz_select();			
			if (this.typ_select == 'resi') this.SelectResidence();
			if (this.typ_select == 'prjt') this.SelectProjet();
			if (this.typ_select == 'epci') this.SelectEPCI();
			if (this.typ_select == 'dist') this.SelectRayon();
			if (this.typ_select == 'epcis') this.SelectEPCIS();
		},

		SelectEPCI() {
			this.raz_epci();
			let url = this.app.BaseUrlBackend + '/insee/getCommunesEpcis?id=' + this.app.adr_init.epci_code;
			const selectedInseeCodes = [];
			var that = this
			axios.get(url, { headers: this.app.HeaderEfocusBack })
			.then(function(response) {
					for (let index = 0; index < response.data.length; index++) {
						const communeId = response.data[index].com_code
						const communeLabel = response.data[index].com_libelle
						selectedInseeCodes.push(communeId);
						that.communesdata[communeId] = communeLabel;
					}
					that.updateCommunes(selectedInseeCodes, true);
				}
			)
/*
			const selectedInseeCodes = this.app.adr_init.epci_city_list;
			this.updateCommunes(selectedInseeCodes, true);
*/
		},

		SelectEPCIS() {
			let url = this.app.BaseUrlBackend + '/insee/getCommunesEpcis?id=' + this.epcis_code.join(',');
			const selectedInseeCodes = [];
			var that = this
			axios.get(url, { headers: this.app.HeaderEfocusBack })
			.then(function(response) {
					for (let index = 0; index < response.data.length; index++) {
						const communeId = response.data[index].com_code
						const communeLabel = response.data[index].com_libelle
						selectedInseeCodes.push(communeId);
						that.communesdata[communeId] = communeLabel;
					}
					that.updateCommunes(selectedInseeCodes, true);
				}
			)
		},

		SelectProjet() {
			this.raz_epci();
			const selectedInseeCodes = [];
			selectedInseeCodes.push(this.codeinsee);
			this.updateCommunes(selectedInseeCodes, true);
		},

		SelectResidence() {
			this.raz_epci();
			const selectedInseeCodes = [];

			for(let i = 0; i < this.app.livedata.synoptique_rs.length; i++){
				for(let k = 0; k < this.app.livedata.synoptique_rs[i].tableau.length; k++){
					let p = this.app.livedata.synoptique_rs[i].tableau[k]
					let Insee = p.insee;
					if (selectedInseeCodes.indexOf(Insee) < 0) selectedInseeCodes.push(Insee)
				}
			}
			/*
			for (let index = 0; index < this.app.rss_filter.length; index++) {
				let Insee = this.app.rss_filter[index].insee;
				if (selectedInseeCodes.indexOf(Insee) < 0) selectedInseeCodes.push(Insee)
			}
			*/
			this.updateCommunes(selectedInseeCodes, true);
		},

		SelectRayon() {
			this.raz_epci();
			const selectedInseeCodes = [];
			let UneUrl = 'https://app.myadequation.fr/map/?service=WFS&version=1.0.0&request=GetFeature&typeName=Web%3Acommune&cql_filter=DWITHIN(geom,%20POINT%20(' + this.app.mrk.lng + '%20' + this.app.mrk.lat + '),' + this.rayon + ',meters)&propertyName=insee&maxFeatures=500&outputFormat=application%2Fjson'
			fetch(UneUrl)
			.then(response => response.json())
			.then(data => {
				data.features.forEach(feature => {
					selectedInseeCodes.push(feature.properties.insee)
				})
			})
			.then(() => {
				// Appeler la fonction ici, après que la boucle `forEach` a été exécutée
				this.updateCommunes(selectedInseeCodes, true);
			})
		},

		getClassTx() {
			if (this.TxStructuration < 3) return 'text-blue-600 font-medium'
			if (this.TxStructuration > 8) return 'text-red-600 font-medium'
			return 'text-teal-600 font-medium'
		},
		getCommuneName(communeId) {
			const communeName = this.communesdata[communeId] + ' (' + communeId + ')';
			return communeName || 'Inconnu';
		},
		center_map: function() {
			this.selectedCommunes = [];
			this.mapVilleEtudiant.setView([this.app.mrk.lat, this.app.mrk.lng]);
		},

		updateCommunes: function(selectedInseeCodes, b) {
			for (let index = 0; index < selectedInseeCodes.length; index++) {
				const communeId = selectedInseeCodes[index];
				try {
					if (this.selectedCommunes.indexOf(communeId) < 0) {
						this.toggleCommuneSelection(communeId,false); // Sélectionner la commune
					}
				} catch (error) {
					console.log(communeId)
				}
			}
			
			
			let UneUrl = 'https://app.myadequation.fr/map/?service=WFS&version=1.0.0&request=GetFeature&typeName=Web:commune&maxFeatures=100000&bbox=' + this.mapVilleEtudiant.getBounds().toBBoxString() + '&outputFormat=application/json'
			fetch(UneUrl)
			.then(response => response.json())
			.then(data => {
				data.features.forEach(feature => {
				const communeId = feature.properties.insee; // Remplacez par la propriété appropriée
				const communeLabel = feature.properties.nom; // Remplacez par la propriété appropriée
				let that = this
				if (!this.communesLayers[communeId] && this.InseeInvisible.indexOf(communeId) == -1) {
					// Ajouter une nouvelle couche si cette commune n'existe pas encore
					const layer = L.geoJSON(feature, {
						style: function(){
							return that.vil_style; 
						},
					// Vous pouvez définir le style ici si nécessaire
					}).addTo(this.mapVilleEtudiant);

					// Ajouter des écouteurs de clic pour la sélection/désélection
					layer.on('click', () => this.toggleCommuneSelection(communeId, true));

					this.communesLayers[communeId] = layer;
					this.communesdata[communeId] = communeLabel;
				}

				try {
					if (this.selectedCommunes.indexOf(communeId) > -1) {
						this.communesLayers[communeId].setStyle(this.sel_style);
					}/* else {
						if (selectedInseeCodes.includes(communeId)) {
							this.communesLayers[communeId].setStyle(this.sel_style);	
							this.toggleCommuneSelection(communeId,false); // Sélectionner la commune
						}
					}*/
				} catch (error) {
					console.log(communeId)
				}

				});
			})
			.then(() => {if (b) this.majTxStructuration();}
			)
			.catch(error => {
				console.error('Erreur lors de la récupération des communes:', error);
			});
		
		},

		toggleCommuneSelection(communeId, b) {
			this.calcencours = true
			const index = this.selectedCommunes.indexOf(communeId);
			if (index > -1) {
				this.selectedCommunes.splice(index, 1); // Retirer l'élément
				if (this.communesLayers[communeId]) this.communesLayers[communeId].resetStyle();
			} else {
				try {
					this.selectedCommunes.push(communeId); // Ajouter l'élément
					if (this.communesLayers[communeId]) this.communesLayers[communeId].setStyle(this.sel_style);
				} catch (error) {
					console.log(communeId)
				}
			}
			if (b) this.majTxStructuration()
		},

		majTxStructuration() {
			const ListeInsee = this.selectedCommunes.join(',');
			const url = this.app.BaseUrlBackend + '/insee/getCumulEtudiant?id=' + ListeInsee;
			
			this.NbMenSenior = 0
			this.NbLogSenior = 0
			this.NbResSenior = 0
			if (!ListeInsee) {
				this.TxStructuration = 0
				this.calcencours = false
			} else {
				let that = this
				that.NbMenSenior = 0
				axios.get(url, { headers: this.app.HeaderEfocusBack })
				.then(function(response) {
					let TabInsee = response.data
					that.NbMenSenior = TabInsee.nb_etudiants
					if (that.NbMenSenior) {
						const urlNbLog = that.app.BaseUrlBackend + '/rss/getNbLog?id=' + ListeInsee + '&nature=(RSE) LIBRE';
						axios.get(urlNbLog, { headers: that.app.HeaderEfocusBack })
						.then(function(responseNbLog) {
							that.NbLogSenior = responseNbLog.data.nb_logement
							that.NbResSenior = responseNbLog.data.nb_residence
							that.TxStructuration = Math.round((that.NbLogSenior / that.NbMenSenior) * 10000)/100
							that.calcencours = false
						})
					} else {
						that.TxStructuration = 0
						that.calcencours = false
					}
				})
			}

		},

	},

	components: { MultiSelect, RadioButton, Slider,  InputText,
        DataTable, 
        Column, 
        Button,
		// eslint-disable-next-line
		Chip}
}
</script>

<style lang="stylus" scoped>
@import '../../../../../assets/css/app'
@import '../../../../../assets/css/grid'


.loader
	display inline-block
	background main_color
	border none
	border-radius 100%
	color #6c21f9
	.icon
		display block
		animation rotate 1.5s infinite linear

.mapVilleEtudiant
	width 100%
	height 632px

.program-detail-info-content
	padding 8px 0
	background #f5f5f5
	border-radius radius
	overflow hidden
	table
		width 100%
		border-collapse collapse
		tr
			&:last-child
				border none
			td, th
				padding 4px 8px
			th
				font-size 12px
				font-weight 500
			td
				text-align center
				&:first-child
					text-align left
					font-size 12px
					font-weight bold
					padding-left 16px
.div-ellipsis
	overflow hidden
	max-height 40px
	position relative
	padding-right 30px
	line-height 1.2em
	&:after
		content '...'
		position absolute
		bottom 0
		right 0
		background linear-gradient(to right, rgba(255,255,255,0), rgba(255,255,255,1) 50%)

:deep(.p-multiselect-header) {
	display: none;
}

:deep(.p-multiselect-items-wrapper) {
	.p-checkbox {
		display: none;
	}
}
.p-multiselect {
	width: 100%;
	max-width: 100vh;
}
					
</style>
