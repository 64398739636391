<template>
	<transition name="pane-adresse-numerique">
		<div class="paneleft pan-adresse-numerique">
			<pane-adresse-retour accueil="" retour="adresse" cityscan="true" v-show="false"/>
			<div class="panecontent" v-bar>
				<div style="height:auto !important">
					<div class="unmodule_detail_titre">
						<div class="titre_sommaire2">
							<span class="fila" @click="$store.dispatch('set_contour_iso', { contour: false });$store.dispatch('set_bienslocatifs', { value: false }); $store.dispatch('set_view', { view: 'adresse' })">{{ $t('Général') }}</span>
							<span> > </span>
							<span><b>{{ $t('Numérique') }}</b></span>
						</div>
						<div class="noteTitre" :class="app.cityscan.adresse.note_numerique">{{app.cityscan.adresse.note_numerique}}</div>
					</div>
					<div class="programs-list">
						<div class="programs-list-content" v-bar>
							<div class="content" ref="scrollable_programs">
								<ul class="accordion-menu">
									<div class="single-accordion">
										<details class="accordion-item" open>
											<summary class="accordion-header" tabindex="0">{{ $t('internet') }}</summary>
											<div class="accordion-content">
												<li class="unmodule_detail">
													<div class="icone_module" :class="getimagenote(this.app.cityscan.adresse.haut_debit.note)" style="width:32px">&nbsp;</div>
													<div class="titre_module">{{ $t('internet_haut_debit') }}</div>
													<div class="note" :class="this.app.cityscan.adresse.haut_debit.note">{{this.app.cityscan.adresse.haut_debit.note}}</div>
													<div class="soustitre_module">{{getlibellenote(this.app.cityscan.adresse.haut_debit.note, 'haut_debit')}}</div>
													<div class="cz" style="top:18px !important;">
														<label for="adresse_haut_debit" class="checkbox" :class="{ checked: adresse_haut_debit }">
															<input type="checkbox" :checked="adresse_haut_debit"   id="adresse_haut_debit" @click=" select_adresse('adresse_haut_debit')">
															<span class="chk"><icon file="check" :size="16" /></span>
															<span class="text"></span>
														</label>
													</div>
												</li>
												<div class="divider"></div>
												<li class="unmodule_detail">
													<div class="icone_module" :class="getimagenote(this.app.cityscan.adresse.fibre.note)" style="width:32px">&nbsp;</div>
													<div class="titre_module">{{ $t('fibre') }}</div>
													<div class="note" :class="this.app.cityscan.adresse.fibre.note">{{this.app.cityscan.adresse.fibre.note}}</div>
													<div class="soustitre_module">{{getlibellenote(this.app.cityscan.adresse.fibre.note, 'fibre')}}</div>
													<div class="cz" style="top:18px !important;">
														<label for="adresse_fibre" class="checkbox" :class="{ checked: adresse_fibre }">
															<input type="checkbox" :checked="adresse_fibre"  id="adresse_fibre" @click=" select_adresse('adresse_fibre')">
															<span class="chk"><icon file="check" :size="16" /></span>
															<span class="text"></span>
														</label>
													</div>
												</li>
											</div>
										</details>
										<details class="accordion-item" open>
											<summary class="accordion-header" tabindex="0">{{ $t('mobile') }}</summary>
											<div class="accordion-content">
												<li class="unmodule_detail">
													<div class="icone_module" :class="getimagenote(this.app.cityscan.adresse.mobile_4G.note)" style="width:32px">&nbsp;</div>
													<div class="titre_module">{{ $t('mobile_4g') }}</div>
													<div class="note" :class="this.app.cityscan.adresse.mobile_4G.note">{{this.app.cityscan.adresse.mobile_4G.note}}</div>
													<div class="soustitre_module">{{getlibellenote(this.app.cityscan.adresse.mobile_4G.note, 'mobile_4G')}}</div>
													<div class="cz" style="top:18px !important;">
														<label for="adresse_mobile_4G" class="checkbox" :class="{ checked: adresse_mobile_4G }" >
															<input type="checkbox" :checked="adresse_mobile_4G" id="adresse_mobile_4G" @click=" select_adresse('adresse_mobile_4G')">
															<span class="chk"><icon file="check" :size="16" /></span>
															<span class="text"></span>
														</label>
													</div>
												</li>
												<div class="divider"></div>
												<li class="unmodule_detail">
													<div class="icone_module" :class="getimagenote(this.app.cityscan.adresse.mobile_3G.note)" style="width:32px">&nbsp;</div>
													<div class="titre_module">{{ $t('mobile_3g') }}</div>
													<div class="note" :class="this.app.cityscan.adresse.mobile_3G.note">{{this.app.cityscan.adresse.mobile_3G.note}}</div>
													<div class="soustitre_module">{{getlibellenote(this.app.cityscan.adresse.mobile_3G.note, 'mobile_3G')}}</div>
													<div class="cz" style="top:18px !important;">
														<label for="adresse_mobile_3G" class="checkbox" :class="{ checked: adresse_mobile_3G }">
															<input type="checkbox" :checked="adresse_mobile_3G"  id="adresse_mobile_3G" @click=" select_adresse('adresse_mobile_3G')">
															<span class="chk"><icon file="check" :size="16" /></span>
															<span class="text"></span>
														</label>
													</div>
												</li>
											</div>
										</details>
									</div>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import { mapGetters } from 'vuex'
import PaneAdresseRetour from './PaneAdresseRetour.vue'

export default {
	name: 'PaneAdresseNumerique',
	data: function() {
		return {
			adresse_mobile_3G: true,
			adresse_mobile_4G: true,
			adresse_fibre: true,
			adresse_haut_debit: true,
			detail: '',
		}
	},

	computed: Object.assign(mapGetters([ 'app', 'map' ]), {
		
	}),

	mounted: function() {
		// Gestion préférences
		//if (this.app.preferences.adresse_niv1.length) {
		//	this.adresse_mobile_3G = (this.app.preferences.adresse_niv2.indexOf('adresse_mobile_3G')!==-1)
		//	this.adresse_mobile_4G = (this.app.preferences.adresse_niv2.indexOf('adresse_mobile_4G')!==-1)
		//	this.adresse_fibre = (this.app.preferences.adresse_niv2.indexOf('adresse_fibre')!==-1)
		//	this.adresse_haut_debit = (this.app.preferences.adresse_niv2.indexOf('adresse_haut_debit')!==-1)
		//}
	},

	methods: {
		select_adresse: function(e) {
			this[e] = !this[e];
			// Gestion préférences
			//this.$store.dispatch('set_preferences_adresses_niv2', {niv2: e, select: this[e]})
		},

		getlibellenote: function(note, theme) {
			switch (theme) {
				case 'fibre':
					switch (note) {
						case 'A': return 'Bâtiment fibré'
						case 'B': return 'Fibre en cours de déploiement'
						default: return 'Non équipé'
					}
				default:
					switch (note) {
						case 'A': return 'Très favorable'
						case 'B': return 'Favorable'
						case 'C': return 'Assez favorable'
						case 'D': return 'Potentiellement défavorable'
						case 'E': return 'Potentiellement très défavorable'
						default: return ''
					}
			}

		},

		getimagenote: function(note) {
			switch (note) {
				case 'E': return 'level_one'
				case 'D': return 'level_two'
				case 'C': return 'level_three'
				case 'B': return 'level_four'
				case 'A': return 'level_five'
				default: return ''
			}
		},		
	},
	
	components: { PaneAdresseRetour }
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/app'

.pane-adresse-numerique-enter
	transform translateX(100%)
.pane-adresse-numerique-leave-to
	transform translateX(-100%)

.pan-adresse-numerique
	position absolute
	top headersize
	float left
	transition 0.5s easeOutQuart

.level_one
	background url(../../assets/img/level_one_green.png) center center no-repeat
.level_two
	background url(../../assets/img/level_two_green.png) center center no-repeat
.level_three
	background url(../../assets/img/level_three_green.png) center center no-repeat
.level_four
	background url(../../assets/img/level_four_green.png) center center no-repeat
.level_five
	background url(../../assets/img/level_five_green.png) center center no-repeat
.cz
	position absolute
	right 4px !important
	top 12px !important

.note
	right 40px !important
	top calc(50% - 8px) !important

.checkbox {
	width: 20px !important;
	height: 20px !important;
}
	
</style>
