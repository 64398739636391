import L from 'leaflet'

const map_helper = {
	build_polygon: function(data) {
		let parcelle = data.parcelle
		let that = data.that
		if (data.geojson) {
			let tab = data.geojson.coordinates
			for (let n=0; n < tab[0].length; n++) {
				tab[0][n].reverse()
			}
			let marker = L.polygon(tab, {color: '#fff', stroke:false, fillOpacity:0})
			marker.parcelle = parcelle
			
			marker.on('mouseover', function(e) {
				e.target.setStyle({color: '#000', stroke:false, fillOpacity:1})
			})
			marker.on('mouseout', function(e) {
				e.target.setStyle({color: '#fff', stroke:false, fillOpacity:0})
			})
			marker.on('click', function(e) {
				let parcelle = e.target.parcelle
				
				that.$store.dispatch('expand_parcelle', { parcelle: parcelle })
				//location.hash = "#parcelle-" + parcelle.id;
			})
			return marker
		} else {
			let classname = 'map-icon ancien'
			let icon = L.divIcon({ className: classname + ' ' + parcelle.propertyType, html: '<div class="pinhex"></div>' });
			if (icon) {
				let markerP = L.marker([parcelle.location.coordinates[0], parcelle.location.coordinates[1]], { icon: icon, title: parcelle.propertyId + ', ' + parcelle.ufId });
//				parcelle.id = parcelle.propertyId
				markerP.parcelle = parcelle
				
				markerP.on('mouseover', function() {
					this._icon.classList.add('hover')
					document.getElementById('parcelle-' + parcelle.id).classList.add('objecthover')
				})
				markerP.on('mouseout', function() {
					this._icon.classList.remove('hover')
					document.getElementById('parcelle-' + parcelle.id).classList.remove('objecthover')
				})
				/*
				markerP.on('mouseover', function() {
					this._icon.classList.add('hover')
					document.getElementById('parcelle-' + parcelle.propertyId).classList.add('objecthover')
				})
				markerP.on('mouseout', function() {
					this._icon.classList.remove('hover')
					document.getElementById('parcelle-' + parcelle.propertyId).classList.remove('objecthover')
				})
				*/
				markerP.on('click', function(e) {
					let parcelle = e.target.parcelle
					that.$store.dispatch('expand_parcelle', { parcelle: parcelle })
					//location.hash = "#parcelle-" + parcelle.propertyId;
				})
				
				return markerP
			}
			return false			
		}
	},
	
	build_marker: function(data) {
		let program = data.program
		let that = data.that
		
		let classname = 'map-icon-rs ' + data.pin_type;
		if (program.expanded) { classname += ' expanded'; }
		if (program.selected) { classname += ' selected'; }
		let icon = false
		let h;
		if (data.rs) {
			let url_svg = 'https://www.myadequation.fr/EFOCUS/pine_efocus-01.svg';
			if (data.cible == 'INTERGENERATIONNEL') url_svg = 'https://www.myadequation.fr/EFOCUS/intergenerationnel.svg';
			if (data.program.cible == 'ETUDIANT') url_svg = 'https://www.myadequation.fr/EFOCUS/etudiants.svg';
			if (data.cible == 'TOUT AGE') url_svg = 'https://www.myadequation.fr/EFOCUS/toutage.svg';
			if (data.program.cible == 'JEUNES') url_svg = 'https://www.myadequation.fr/EFOCUS/jeunes.svg';
			if (data.program.cible == 'SENIOR') url_svg = 'https://www.myadequation.fr/EFOCUS/seniors.svg';
			if (data.program.cible == 'SOCIAL') url_svg = 'https://www.myadequation.fr/EFOCUS/social.svg';
			if (data.program.cible == 'TOURISTE') url_svg = 'https://www.myadequation.fr/EFOCUS/tourisme.svg';
			let s_html = `<div class="pinrs"style="
			padding-right: 0px;
			background-image: url('` + url_svg + `');
			background-size: contain;
			background-repeat: no-repeat;
			height: 50px;width: 36px;
			"></div>`
			const svgIconRs = L.divIcon({
				html: s_html,
				className: classname,
				iconSize: [36, 50], iconAnchor: [18, 50],
			});
			var f = 'collectif'
			icon = L.divIcon({ className: classname + ' ' + data.gamme});
			let marker
			if (svgIconRs) {
				if (program.pc) {
					h = "pc"
					icon = L.divIcon({ className: h, html: '<div class="pin"></div>', iconSize: [36, 50], iconAnchor: [18, 50],});
					marker = L.marker([program.location.lat, program.location.lon], { icon: icon, title: program.nom + ', ' + program.gestionnaire });	
				} else {
					marker = L.marker([program.location.lat, program.location.lon], { icon: svgIconRs, title: program.nom + ', ' + program.gestionnaire });
				}
				marker.program = program
				
				marker.on('mouseover', function() {
					if (that.app.view == 'rs') this._icon.classList.add('hover')
						document.getElementById('rs-' + program.id).classList.add('objecthover')
					})
				marker.on('mouseout', function() {
					if (that.app.view == 'rs') this._icon.classList.remove('hover')
						document.getElementById('rs-' + program.id).classList.remove('objecthover')
					})
				marker.on('click', function(e) {
					let program = e.target.program
					that.$store.dispatch('expand_rs', { rs: program })
					that.$router.push({ name: 'rs', params: { id: program.id }}).catch(()=>{});
				})
				
				return marker
			}
		} else if (data.rpls) {
			let url_rpls_svg = 'https://www.myadequation.fr/EFOCUS/rs.svg';
			let s_rpls_html = `<div class="pinrs"style="
			padding-right: 0px;
			background-image: url('` + url_rpls_svg + `');
			background-size: contain;
			background-repeat: no-repeat;
			height: 16px;width: 16px;
			"></div>`
			const svgIconRpls = L.divIcon({
				html: s_rpls_html,
				className: classname,
				iconSize: [16, 16], iconAnchor: [8, 8],
			});
			icon = L.divIcon({ className: classname + ' ' + data.gamme});
			let markerRpls
			if (svgIconRpls) {
				markerRpls = L.marker([program.position.lat, program.position.lon], { icon: svgIconRpls, title: program.NOM + ', ' + program.LIBSEGPATRIM });
				markerRpls.program = program
				
				markerRpls.on('mouseover', function() {
					if (that.app.view == 'rpls') this._icon.classList.add('hover')
						document.getElementById('rpls-' + program.residence).classList.add('objecthover')
					})
				markerRpls.on('mouseout', function() {
					if (that.app.view == 'rpls') this._icon.classList.remove('hover')
						document.getElementById('rpls-' + program.residence).classList.remove('objecthover')
					})
				markerRpls.on('click', function(e) {
					let program = e.target.program
					that.$store.dispatch('expand_rpls', { rpls: program })
					that.$router.push({ name: 'rpls', params: { id: program.residence }}).catch(()=>{});
				})
				
				return markerRpls
			}
		} else {
				
			let c
			let g
			if (program.pc) {
				c = "pc"
			} else {
				if (program.forme_urbaine.toLowerCase()!='individuel') {
					g = "collectif_encours"
					if (data.program.COMMERCIALISATION == 'termine') g = "collectif_termine"
					if (data.gamme == 'entree') {
						c = "collectif_entree"
					} else {
						if (data.gamme == 'milieu') {
							c = "collectif_milieu"
						} else {
							c = "collectif_haut"
						}
					}
				} else {
					g = "individuel_encours"
					if (data.program.COMMERCIALISATION == 'termine') g = "individuel_termine"
					if (data.gamme == 'entree') {
						c = "individuel_entree"
					} else {
						if (data.gamme == 'milieu') {
							c = "individuel_milieu"
						} else {
							c = "individuel_haut"
						}
					}
				}
			}
			
			let programR = data.program
			let dthat = data.that
			
			let classnameR = 'map-icon ' + data.pin_type;
			if (programR.expanded) { classnameR += ' expanded'; }
			if (programR.selected) { classnameR += ' selected'; }
			
			icon = false
			
			f = 'collectif'
			if (programR.forme_urbaine) f = programR.forme_urbaine.toLowerCase()
				if (f!='individuel') f = 'collectif'
			h = g
			if (data.pin_type == 'commercialisation') {
				h = g
				icon = L.divIcon({ className: classnameR + ' ' + programR.COMMERCIALISATION, html: '<div class="pin"></div>' });
			}
			else if (data.pin_type == 'prix') {
				h = c
				if (programR.pc) {
					icon = L.divIcon({ className: classnameR + ' ' + programR.COMMERCIALISATION, html: '<div class="pin"></div>' });
				} else {
					icon = L.divIcon({ className: classnameR + ' ' + data.gamme, html: '<div class="pin"></div>' });
				}
			} else if (data.pin_type == 'forme') {
				if (programR.pc) {
					icon = L.divIcon({ className: classnameR + ' ' + programR.COMMERCIALISATION, html: '<div class="pin"></div>' });
				} else {
					icon = L.divIcon({ className: classnameR + ' ' + f, html: '<div class="pin"></div>' });
				}
			}
			else if (data.pin_type == 'tva') {
				if (programR.pc) {
					icon = L.divIcon({ className: classnameR + ' ' + programR.COMMERCIALISATION, html: '<div class="pin"></div>' });
				} else {
					icon = L.divIcon({ className: classnameR, html: '<div class="pin"><div class="label">' + program.TVA + '%</div></div>' });
				}
			}
			if (program.pc) {
				h = 'pc'
			}
			icon = L.divIcon({ className: h, html: '<div class="pin"></div>', iconSize: [36, 50], iconAnchor: [18, 50],});
			if (icon) {
				let markerR = L.marker([program.location.lat, program.location.lon], { icon: icon, title: program.nom + ', ' + program.promoteur });
				markerR.program = program
				
				markerR.on('mouseover', function() {
					if (dthat.app.view == 'list') this._icon.classList.add('hover')
						document.getElementById('program-' + program.id).classList.add('objecthover')
					})
				markerR.on('mouseout', function() {
					if (dthat.app.view == 'list') this._icon.classList.remove('hover')
						document.getElementById('program-' + program.id).classList.remove('objecthover')
					})
				markerR.on('click', function(e) {
					let programC = e.target.program
					dthat.$store.dispatch('expand_program', { program: programC })
					dthat.$router.push({ name: 'program', params: { id: programC.id }}).catch(()=>{});
				})
				
				return markerR
			}
		}
		return false
	},

	build_offre: function(data) {
		let program = data.program
		let that = data.that
		
		let classname = 'map-icon-rs ' + data.pin_type;
		if (program.expanded) { classname += ' expanded'; }
		if (program.selected) { classname += ' selected'; }
			let url_rpls_svg = 'https://www.myadequation.fr/EFOCUS/bb.svg';
			if (program.propertyType !='flat') url_rpls_svg = 'https://www.myadequation.fr/EFOCUS/gg.svg';
			let s_rpls_html = `<div class="pinoffre"style="
			padding-right: 0px;
			background-image: url('` + url_rpls_svg + `');
			background-size: contain;
			background-repeat: no-repeat;
			height: 16px;width: 16px;
			"></div>`
			const svgIconRpls = L.divIcon({
				html: s_rpls_html,
				className: classname,
				iconSize: [16, 16], iconAnchor: [8, 8],
			});

			let marker
			if (svgIconRpls) {
				marker = L.marker([program.position.lat, program.position.lon], { icon: svgIconRpls, });
				marker.offre = program
				
				marker.on('mouseover', function() {
					if (that.app.view == 'ancien-offre') this._icon.classList.add('hover')
						document.getElementById('offre-ancien-' + program.id).classList.add('objecthover')
					})
				marker.on('mouseout', function() {
					if (that.app.view == 'ancien-offre') this._icon.classList.remove('hover')
						document.getElementById('offre-ancien-' + program.id).classList.remove('objecthover')
					})
				marker.on('click', function(e) {
					let offre = e.target.offre
					that.$store.dispatch('expand_offre', { offre: offre })
					that.$router.push({ name: 'offre', params: { id: offre.id }}).catch(()=>{});
				})
				
				return marker
			}
		return false
	},

	geocodeAddress: function (address) {
		return new Promise((resolve, reject) => {
			const geocoder = new google.maps.Geocoder();
		
			geocoder.geocode({ 'address': address }, function(results, status) {
			if (status === 'OK') {
				const location = results[0].geometry.location;
				const coordinates = {
				lat: location.lat(),
				lng: location.lng()
				};
				resolve(coordinates);
			} else {
				reject('Geocode was not successful for the following reason: ' + status);
			}
			});
		});
	},

	build_locatif: function(data) {
		let program = data.program
		let that = data.that
		
		let classname = 'map-icon-rs ' + data.pin_type;
		if (program.expanded) { classname += ' expanded'; }
		if (program.selected) { classname += ' selected'; }
		
			let url_rpls_svg = 'https://www.myadequation.fr/EFOCUS/rr.svg';
			let s_rpls_html = `<div class="pinoffre"style="
			padding-right: 0px;
			background-image: url('` + url_rpls_svg + `');
			background-size: contain;
			background-repeat: no-repeat;
			height: 16px;width: 16px;
			"></div>`
			const svgIconRpls = L.divIcon({
				html: s_rpls_html,
				className: classname,
				iconSize: [16, 16], iconAnchor: [8, 8],
			});


			let marker
			if (svgIconRpls) {
				let lat
				let lon
				if (program.address.location) {
					lat = program.address.location.lat
					lon = program.address.location.lon
				} else {
					if (program.address.approximateLocation) {
						if (program.address.approximateLocation.type == "Circle") {
							lat = program.address.approximateLocation.coordinates[1]
							lon = program.address.approximateLocation.coordinates[0]
						} else {
							let v
							if (program.address.approximateLocation.type == "Polygon") {
								let polygon = turf.polygon(program.address.approximateLocation.coordinates);
								v = turf.pointOnFeature(polygon);
								lat = v.geometry.coordinates[1]
								lon = v.geometry.coordinates[0]
							} else {
								let multipolygon = turf.polygon(program.address.approximateLocation.coordinates[0]);
								v = turf.pointOnFeature(multipolygon);
								lat = v.geometry.coordinates[1]
								lon = v.geometry.coordinates[0]
							}
						}
					} else {
						console.log(program.address)
					}
				}
				marker = L.marker([lat, lon], { icon: svgIconRpls, });
				marker.annonce = program
				
				marker.on('mouseover', function() {
					if (that.app.view == 'ancien-locatif') this._icon.classList.add('hover')
						document.getElementById('annonce-' + program.id).classList.add('objecthover')
					})
				marker.on('mouseout', function() {
					if (that.app.view == 'ancien-locatif') this._icon.classList.remove('hover')
						document.getElementById('annonce-' + program.id).classList.remove('objecthover')
					})
				marker.on('click', function(e) {
					let annonce = e.target.annonce
					that.$store.dispatch('expand_annonce', { annonce: annonce })
					that.$router.push({ name: 'annonce', params: { id: annonce.id }}).catch(()=>{});
				})
				
				return marker
			}
		return false
	}
}

export default map_helper