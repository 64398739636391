<template>
	<transition name="pane-adresse-services">
		<div class="paneleft pan-adresse-services">
			<pane-adresse-retour accueil="" retour="adresse" cityscan="true" v-show="false"/>
			<div class="panecontent" v-bar>
				<div style="height:auto !important">
					<div class="unmodule_detail_titre">
						<div class="titre_sommaire2">
							<span class="fila" @click="$store.dispatch('set_contour_iso', { contour: false });$store.dispatch('set_bienslocatifs', { value: false }); $store.dispatch('set_view', { view: 'adresse' })">{{$t('Général')}}</span>
							<span> > </span>
							<span><b>{{ $t('Services publics') }}</b></span>
						</div>
						<div class="noteTitre" :class="app.cityscan.adresse.note_services">{{app.cityscan.adresse.note_services}}</div>
					</div>
					<div class="programs-list">
						<div class="programs-list-content" v-bar>
							<div class="content" ref="scrollable_programs">
								<ul class="accordion-menu">
									<div class="single-accordion">
										<details class="accordion-item" open>
											<summary class="accordion-header" tabindex="0">{{ $t('securite') }}</summary>
											<div class="accordion-content">
												<li class="unmodule_detail hov" style="cursor:pointer">
													<div @click="$store.dispatch('set_view', { view: 'service-commissariat' }); select_adresse_iso(app.cityscan.adresse.commissariat)">
														<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-1300-02 ng-star-inserted" style="color: rgb(174, 31, 19);"></span></div>
														<div class="titre_module">{{ $t('commissariat') }}</div>
														<div class="note" :class="this.app.cityscan.adresse.commissariat.note">{{this.app.cityscan.adresse.commissariat.note}}</div>
														<div class="soustitre_module">{{this.app.cityscan.adresse.commissariat.nb}} {{this.app.cityscan.adresse.commissariat.temps}}</div>
													</div>
													<div class="cz" style="top:18px !important;">
														<label for="adresse_service_commissariat" class="checkbox" :class="{ checked: adresse_service_commissariat }">
															<input type="checkbox" :checked="adresse_service_commissariat"  id="adresse_service_commissariat" @click=" select_adresse('adresse_service_commissariat', app.cityscan.adresse.commissariat)">
															<span class="chk"><icon file="check" :size="16" /></span>
															<span class="text"></span>
														</label>
													</div>
												</li>
												<div class="divider"></div>
												<li class="unmodule_detail hov" style="cursor:pointer">
													<div @click="$store.dispatch('set_view', { view: 'service-gendarmerie' }); select_adresse_iso(app.cityscan.adresse.gendarmerie)">
														<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-1301-02 ng-star-inserted" style="color: rgb(220, 43, 29);"></span></div>
														<div class="titre_module">{{ $t('gendarmerie') }}</div>
														<div class="note" :class="this.app.cityscan.adresse.gendarmerie.note">{{this.app.cityscan.adresse.gendarmerie.note}}</div>
														<div class="soustitre_module">{{this.app.cityscan.adresse.gendarmerie.nb}} {{this.app.cityscan.adresse.gendarmerie.temps}}</div>
													</div>
													<div class="cz" style="top:18px !important;">
														<label for="adresse_service_gendarmerie" class="checkbox" :class="{ checked: adresse_service_gendarmerie }">
															<input type="checkbox" :checked="adresse_service_gendarmerie"  id="adresse_service_gendarmerie" @click=" select_adresse('adresse_service_gendarmerie', app.cityscan.adresse.gendarmerie)">
															<span class="chk"><icon file="check" :size="16" /></span>
															<span class="text"></span>
														</label>
													</div>
												</li>
											</div>
										</details>
										<details class="accordion-item" open>
											<summary class="accordion-header" tabindex="0">{{ $t('sante_urgence') }}</summary>
											<div class="accordion-content">
												<li class="unmodule_detail hov" style="cursor:pointer">
													<div @click="$store.dispatch('set_view', { view: 'service-pompier' }); select_adresse_iso(app.cityscan.adresse.pompiers)">
														<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-1310-02 ng-star-inserted" style="color: rgb(246, 107, 99);"></span></div>
														<div class="titre_module">{{ $t('pompiers') }}</div>
														<div class="note" :class="this.app.cityscan.adresse.pompiers.note">{{this.app.cityscan.adresse.pompiers.note}}</div>
														<div class="soustitre_module">{{this.app.cityscan.adresse.pompiers.nb}} {{this.app.cityscan.adresse.pompiers.temps}}</div>
													</div>
													<div class="cz" style="top:18px !important;">
														<label for="adresse_service_pompier" class="checkbox" :class="{ checked: adresse_service_pompier }">
															<input type="checkbox" :checked="adresse_service_pompier"  id="adresse_service_pompier" @click=" select_adresse('adresse_service_pompier', app.cityscan.adresse.pompiers)">
															<span class="chk"><icon file="check" :size="16" /></span>
															<span class="text"></span>
														</label>
													</div>
												</li>
												<div class="divider"></div>
												<li class="unmodule_detail hov" style="cursor:pointer">
													<div @click="$store.dispatch('set_view', { view: 'service-hopital' }); select_adresse_iso(app.cityscan.adresse.urgences)">
														<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-1311-02 ng-star-inserted" style="color: rgb(246, 47, 72);"></span></div>
														<div class="titre_module">{{ $t('hôpital') }}</div>
														<div class="note" :class="this.app.cityscan.adresse.urgences.note">{{this.app.cityscan.adresse.urgences.note}}</div>
														<div class="soustitre_module">{{this.app.cityscan.adresse.urgences.nb}} {{this.app.cityscan.adresse.urgences.temps}}</div>
													</div>
													<div class="cz" style="top:18px !important;">
														<label for="adresse_service_hopital" class="checkbox" :class="{ checked: adresse_service_hopital }" >
															<input type="checkbox" :checked="adresse_service_hopital" id="adresse_service_hopital" @click=" select_adresse('adresse_service_hopital', app.cityscan.adresse.urgences)">
															<span class="chk"><icon file="check" :size="16" /></span>
															<span class="text"></span>
														</label>
													</div>
												</li>
											</div>
										</details>
										<details class="accordion-item" open>
											<summary class="accordion-header" tabindex="0">{{ $t('sante_quotidienne') }}</summary>
											<div class="accordion-content">
												<li class="unmodule_detail hov" style="cursor:pointer">
													<div @click="$store.dispatch('set_view', { view: 'service-laboratoire' }); select_adresse_iso(app.cityscan.adresse.laboratoires)">
														<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-1322-02 ng-star-inserted" style="color: rgb(78, 161, 50);"></span></div>
														<div class="titre_module">{{ $t('laboratoire_medical') }}</div>
														<div class="note" :class="this.app.cityscan.adresse.laboratoires.note">{{this.app.cityscan.adresse.laboratoires.note}}</div>
														<div class="soustitre_module">{{this.app.cityscan.adresse.laboratoires.nb}} {{this.app.cityscan.adresse.laboratoires.temps}}</div>
													</div>
													<div class="cz" style="top:18px !important;">
														<label for="adresse_service_laboratoire" class="checkbox" :class="{ checked: adresse_service_laboratoire }">
															<input type="checkbox" :checked="adresse_service_laboratoire"  id="adresse_service_laboratoire" @click=" select_adresse('adresse_service_laboratoire', app.cityscan.adresse.laboratoires)">
															<span class="chk"><icon file="check" :size="16" /></span>
															<span class="text"></span>
														</label>
													</div>
												</li>
												<div class="divider"></div>
												<li class="unmodule_detail hov" style="cursor:pointer">
													<div @click="$store.dispatch('set_view', { view: 'service-agees' }); select_adresse_iso(app.cityscan.adresse.personnes_agees)">
														<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-1372-02 ng-star-inserted" style="color: rgb(64, 140, 38);"></span></div>
														<div class="titre_module">{{ $t('services_aux_personnes_âgees') }}</div>
														<div class="note" :class="this.app.cityscan.adresse.personnes_agees.note">{{this.app.cityscan.adresse.personnes_agees.note}}</div>
														<div class="soustitre_module">{{this.app.cityscan.adresse.personnes_agees.nb}} {{this.app.cityscan.adresse.personnes_agees.temps}}</div>
													</div>
													<div class="cz" style="top:18px !important;">
														<label for="adresse_service_agees" class="checkbox" :class="{ checked: adresse_service_agees }" >
															<input type="checkbox" :checked="adresse_service_agees" id="adresse_service_agees" @click=" select_adresse('adresse_service_agees', app.cityscan.adresse.personnes_agees)">
															<span class="chk"><icon file="check" :size="16" /></span>
															<span class="text"></span>
														</label>
													</div>
												</li>
												<div class="divider"></div>
												<li class="unmodule_detail hov" style="cursor:pointer">
													<div @click="$store.dispatch('set_view', { view: 'service-pharmacie' }); select_adresse_iso(app.cityscan.adresse.pharmacie)">
														<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-1321-02 ng-star-inserted" style="color: rgb(22, 76, 0);"></span></div>
														<div class="titre_module">{{ $t('pharmacie') }}</div>
														<div class="note" :class="this.app.cityscan.adresse.pharmacie.note">{{this.app.cityscan.adresse.pharmacie.note}}</div>
														<div class="soustitre_module">{{this.app.cityscan.adresse.pharmacie.nb}} {{this.app.cityscan.adresse.pharmacie.temps}}</div>
													</div>
													<div class="cz" style="top:18px !important;">
														<label for="adresse_service_pharmacie" class="checkbox" :class="{ checked: adresse_service_pharmacie }" >
															<input type="checkbox" :checked="adresse_service_pharmacie" id="adresse_service_pharmacie" @click=" select_adresse('adresse_service_pharmacie', app.cityscan.adresse.pharmacie)">
															<span class="chk"><icon file="check" :size="16" /></span>
															<span class="text"></span>
														</label>
													</div>
												</li>
											</div>
										</details>
										<details class="accordion-item" open>
											<summary class="accordion-header" tabindex="0">{{ $t('medecin') }}</summary>
											<div class="accordion-content">
												<li class="unmodule_detail hov" style="cursor:pointer">
													<div @click="$store.dispatch('set_view', { view: 'service-medecin' }); select_adresse_iso(app.cityscan.adresse.medecin)">
														<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-1320-02 ng-star-inserted" style="color: rgb(36, 97, 13);"></span></div>
														<div class="titre_module">{{ $t('medecin_generaliste') }}</div>
														<div class="note" :class="this.app.cityscan.adresse.medecin.note">{{this.app.cityscan.adresse.medecin.note}}</div>
														<div class="soustitre_module">{{this.app.cityscan.adresse.medecin.nb}} {{this.app.cityscan.adresse.medecin.temps}}</div>
													</div>
													<div class="cz" style="top:18px !important;">
														<label for="adresse_service_medecin" class="checkbox" :class="{ checked: adresse_service_medecin }" >
															<input type="checkbox" :checked="adresse_service_medecin" id="adresse_service_medecin" @click=" select_adresse('adresse_service_medecin', app.cityscan.adresse.medecin)">
															<span class="chk"><icon file="check" :size="16" /></span>
															<span class="text"></span>
														</label>
													</div>
												</li>
												<div class="divider"></div>
												<li class="unmodule_detail hov" style="cursor:pointer">
													<div @click="$store.dispatch('set_view', { view: 'service-specialiste' }); select_adresse_iso(app.cityscan.adresse.specialistes)">
														<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-1323-02 ng-star-inserted" style="color: rgb(22, 76, 0);"></span></div>
														<div class="titre_module">{{ $t('medecin_specialiste') }}</div>
														<div class="note" :class="this.app.cityscan.adresse.specialistes.note">{{this.app.cityscan.adresse.specialistes.note}}</div>
														<div class="soustitre_module">{{this.app.cityscan.adresse.specialistes.nb}} {{this.app.cityscan.adresse.specialistes.temps}}</div>
													</div>
													<div class="cz" style="top:18px !important;">
														<label for="adresse_service_specialiste" class="checkbox" :class="{ checked: adresse_service_specialiste }" >
															<input type="checkbox" :checked="adresse_service_specialiste" id="adresse_service_specialiste" @click=" select_adresse('adresse_service_specialiste', app.cityscan.adresse.specialistes)">
															<span class="chk"><icon file="check" :size="16" /></span>
															<span class="text"></span>
														</label>
													</div>
												</li>
											</div>
										</details>
										<details class="accordion-item" open>
											<summary class="accordion-header" tabindex="0">{{ $t('services_pratiques') }}</summary>
											<div class="accordion-content">
												<li class="unmodule_detail hov" style="cursor:pointer">
													<div @click="$store.dispatch('set_view', { view: 'service-poste' }); select_adresse_iso(app.cityscan.adresse.poste)">
														<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-1380-02 ng-star-inserted" style="color: rgb(255, 170, 34);"></span></div>
														<div class="titre_module">Poste</div>
														<div class="note" :class="this.app.cityscan.adresse.poste.note">{{this.app.cityscan.adresse.poste.note}}</div>
														<div class="soustitre_module">{{this.app.cityscan.adresse.poste.nb}} {{this.app.cityscan.adresse.poste.temps}}</div>
													</div>
													<div class="cz" style="top:18px !important;">
														<label for="adresse_service_poste" class="checkbox" :class="{ checked: adresse_service_poste }">
															<input type="checkbox" :checked="adresse_service_poste"  id="adresse_service_poste" @click=" select_adresse('adresse_service_poste', app.cityscan.adresse.poste)">
															<span class="chk"><icon file="check" :size="16" /></span>
															<span class="text"></span>
														</label>
													</div>
												</li>
												<div class="divider"></div>
												<li class="unmodule_detail hov" style="cursor:pointer">
													<div @click="$store.dispatch('set_view', { view: 'service-recyclage' }); select_adresse_iso(app.cityscan.adresse.recyclage)">
														<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-2010-02 ng-star-inserted" style="color: rgb(120, 155, 114);"></span></div>
														<div class="titre_module">{{ $t('recyclage') }}</div>
														<div class="note" :class="this.app.cityscan.adresse.recyclage.note">{{this.app.cityscan.adresse.recyclage.note}}</div>
														<div class="soustitre_module">{{this.app.cityscan.adresse.recyclage.nb}} {{this.app.cityscan.adresse.recyclage.temps}}</div>
													</div>
													<div class="cz" style="top:18px !important;">
														<label for="adresse_service_recyclage" class="checkbox" :class="{ checked: adresse_service_recyclage }" >
															<input type="checkbox" :checked="adresse_service_recyclage" id="adresse_service_recyclage" @click=" select_adresse('adresse_service_recyclage', app.cityscan.adresse.recyclage)">
															<span class="chk"><icon file="check" :size="16" /></span>
															<span class="text"></span>
														</label>
													</div>
												</li>
												<div class="divider"></div>
												<li class="unmodule_detail hov" style="cursor:pointer">
													<div @click="$store.dispatch('set_view', { view: 'service-dechet' }); select_adresse_iso(app.cityscan.adresse.dechets)">
														<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-2011-02 ng-star-inserted" style="color: rgb(42, 53, 47);"></span></div>
														<div class="titre_module">{{ $t('dechets_menagers') }}</div>
														<div class="note" :class="this.app.cityscan.adresse.dechets.note">{{this.app.cityscan.adresse.dechets.note}}</div>
														<div class="soustitre_module">{{this.app.cityscan.adresse.dechets.nb}} {{this.app.cityscan.adresse.dechets.temps}}</div>
													</div>
													<div class="cz" style="top:18px !important;">
														<label for="adresse_service_dechet" class="checkbox" :class="{ checked: adresse_service_dechet }" >
															<input type="checkbox" :checked="adresse_service_dechet" id="adresse_service_dechet" @click=" select_adresse('adresse_service_dechet', app.cityscan.adresse.dechets)">
															<span class="chk"><icon file="check" :size="16" /></span>
															<span class="text"></span>
														</label>
													</div>
												</li>
											</div>
										</details>
									</div>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import { mapGetters } from 'vuex'
import PaneAdresseRetour from './PaneAdresseRetour.vue'

export default {
	name: 'PaneAdresseServices',
	data: function() {
		return {
			adresse_service_dechet: true,
			adresse_service_recyclage: true,
			adresse_service_poste: true,
			adresse_service_specialiste: true,
			adresse_service_medecin: true,
			adresse_service_pharmacie: true,
			adresse_service_agees: true,
			adresse_service_laboratoire: true,
			adresse_service_hopital: true,
			adresse_service_pompier: true,
			adresse_service_gendarmerie: true,
			adresse_service_commissariat: true,
			detail: '',
		}
	},

	computed: Object.assign(mapGetters([ 'app', 'map' ]), {
		
	}),

	mounted: function() {
		// Gestion préférences
		//if (this.app.preferences.adresse_niv1.length) {
		//	this.adresse_service_dechet = (this.app.preferences.adresse_niv2.indexOf('adresse_service_dechet')!==-1)
		//	this.adresse_service_recyclage = (this.app.preferences.adresse_niv2.indexOf('adresse_service_recyclage')!==-1)
		//	this.adresse_service_poste = (this.app.preferences.adresse_niv2.indexOf('adresse_service_poste')!==-1)
		//	this.adresse_service_specialiste = (this.app.preferences.adresse_niv2.indexOf('adresse_service_specialiste')!==-1)
		//	this.adresse_service_medecin = (this.app.preferences.adresse_niv2.indexOf('adresse_service_medecin')!==-1)
		//	this.adresse_service_pharmacie = (this.app.preferences.adresse_niv2.indexOf('adresse_service_pharmacie')!==-1)
		//	this.adresse_service_agees = (this.app.preferences.adresse_niv2.indexOf('adresse_service_agees')!==-1)
		//	this.adresse_service_laboratoire = (this.app.preferences.adresse_niv2.indexOf('adresse_service_laboratoire')!==-1)
		//	this.adresse_service_hopital = (this.app.preferences.adresse_niv2.indexOf('adresse_service_hopital')!==-1)
		//	this.adresse_service_pompier = (this.app.preferences.adresse_niv2.indexOf('adresse_service_pompier')!==-1)
		//	this.adresse_service_gendarmerie = (this.app.preferences.adresse_niv2.indexOf('adresse_service_gendarmerie')!==-1)
		//	this.adresse_service_commissariat = (this.app.preferences.adresse_niv2.indexOf('adresse_service_commissariat')!==-1)
		//}
	},

	methods: {
		select_adresse: function(e) {
			this[e] = !this[e];
			// Gestion préférences
			//this.$store.dispatch('set_preferences_adresses_niv2', {niv2: e, select: this[e]})
		},
		select_adresse_iso: function(propObj) {
			this.$store.dispatch('set_contour_iso_param', {duration: propObj.duration, transportType: propObj.transportType})
			this.$store.dispatch('set_contour_iso', { contour: true })
		},

		getscore: function(score) {
			let score_name= ''
			if (score >= 80) {
				score_name = "Nutri-score-A"
			} else {
				if (score >= 60) {
					score_name = "Nutri-score-B"
				} else {
					if (score >= 40) {
						score_name = "Nutri-score-C"
					} else {
						if (score >= 20) {
							score_name = "Nutri-score-D"
						} else {
							score_name = "Nutri-score-E"
						}
					}
				}
			}
			return score_name
		},

		details: function(d) {
			if (this.detail==d) {
				this.detail=''
			}
			else {
				this.detail=d
			}
		}
	},

	components: { PaneAdresseRetour }
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/app'

.pane-adresse-services-enter
	transform translateX(100%)
.pane-adresse-services-leave-to
	transform translateX(-100%)

.pan-adresse-services
	position absolute
	top headersize
	float left
	transition 0.5s easeOutQuart
.cz
	position absolute
	right 4px !important
	top 12px !important

.note
	right 40px !important
	top calc(50% - 8px) !important

.checkbox {
	width: 20px !important;
	height: 20px !important;
}
	
</style>
