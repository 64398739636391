<template>
	<transition name="pane-adresse-loisirs">
		<div class="paneleft pane-adresse-loisirs">
			<pane-adresse-retour accueil="" retour="adresse" cityscan="true" v-show="false"/>
			<div class="panecontent" v-bar>
				<div style="height:auto !important">
					<div class="unmodule_detail_titre">
						<div class="titre_sommaire2">
							<span class="fila" @click="$store.dispatch('set_contour_iso', { contour: false });$store.dispatch('set_bienslocatifs', { value: false }); $store.dispatch('set_view', { view: 'adresse' })">{{ $t('Général') }}</span>
							<span> > </span>
							<span><b>{{ $t('Loisirs') }}</b></span>
						</div>

						<div class="noteTitre" :class="app.cityscan.adresse.note_loisirs">{{app.cityscan.adresse.note_loisirs}}</div>
					</div>
					<div class="programs-list">
						<div class="programs-list-content" v-bar>
							<div class="content" ref="scrollable_programs">
								<ul class="accordion-menu">
									<div class="single-accordion">
										<details class="accordion-item" open>
											<summary class="accordion-header" tabindex="0">{{ $t('culture') }}</summary>
											<div class="accordion-content">
												<li class="unmodule_detail hov" style="cursor:pointer">
													<div @click="$store.dispatch('set_view', { view: 'loisir-bibliotheque' }); select_adresse_iso(app.cityscan.adresse.bibliotheques)">
														<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-1350-02 ng-star-inserted" style="color: rgb(0, 214, 242);"></span></div>
														<div class="titre_module">{{ $t('bibliotheque') }}</div>
														<div class="note" :class="this.app.cityscan.adresse.bibliotheques.note">{{this.app.cityscan.adresse.bibliotheques.note}}</div>
														<div class="soustitre_module">{{this.app.cityscan.adresse.bibliotheques.nb}} {{this.app.cityscan.adresse.bibliotheques.temps}}</div>
													</div>
													<div class="cz" style="top:18px !important;">
														<label for="adresse_loisir_bibliotheque" class="checkbox" :class="{ checked: adresse_loisir_bibliotheque }" >
															<input type="checkbox" :checked="adresse_loisir_bibliotheque" id="adresse_loisir_bibliotheque" @click=" select_adresse('adresse_loisir_bibliotheque', app.cityscan.adresse.bibliotheques)">
															<span class="chk"><icon file="check" :size="16" /></span>
															<span class="text"></span>
														</label>
													</div>
												</li>
												<div class="divider"></div>
												<li class="unmodule_detail hov" style="cursor:pointer">
													<div @click="$store.dispatch('set_view', { view: 'loisir-theatre' }); select_adresse_iso(app.cityscan.adresse.theatre)">
														<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-1351-02 ng-star-inserted" style="color: rgb(0, 179, 211);"></span></div>
														<div class="titre_module">{{ $t('theâtre_&_opera') }}</div>
														<div class="note" :class="this.app.cityscan.adresse.theatre.note">{{this.app.cityscan.adresse.theatre.note}}</div>
														<div class="soustitre_module">{{this.app.cityscan.adresse.theatre.nb}} {{this.app.cityscan.adresse.theatre.temps}}</div>
													</div>
													<div class="cz" style="top:18px !important;">
														<label for="adresse_loisir_theatre" class="checkbox" :class="{ checked: adresse_loisir_theatre }">
															<input type="checkbox" :checked="adresse_loisir_theatre"  id="adresse_loisir_theatre" @click=" select_adresse('adresse_loisir_theatre'), app.cityscan.adresse.theatre">
															<span class="chk"><icon file="check" :size="16" /></span>
															<span class="text"></span>
														</label>
													</div>
												</li>
												<div class="divider"></div>
												<li class="unmodule_detail hov" style="cursor:pointer">
													<div @click="$store.dispatch('set_view', { view: 'loisir-musee' }); select_adresse_iso(app.cityscan.adresse.musees)">
														<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-1352-02 ng-star-inserted" style="color: rgb(0, 143, 179);"></span></div>
														<div class="titre_module">{{ $t('musee') }}</div>
														<div class="note" :class="this.app.cityscan.adresse.musees.note">{{this.app.cityscan.adresse.musees.note}}</div>
														<div class="soustitre_module">{{this.app.cityscan.adresse.musees.nb}} {{this.app.cityscan.adresse.musees.temps}}</div>
													</div>
													<div class="cz" style="top:18px !important;">
														<label for="adresse_loisir_musee" class="checkbox" :class="{ checked: adresse_loisir_musee }" >
															<input type="checkbox" :checked="adresse_loisir_musee" id="adresse_loisir_musee" @click=" select_adresse('adresse_loisir_musee', app.cityscan.adresse.musees)">
															<span class="chk"><icon file="check" :size="16" /></span>
															<span class="text"></span>
														</label>
													</div>
												</li>
											</div>
										</details>
										<details class="accordion-item" open>
											<summary class="accordion-header" tabindex="0">{{ $t('sorties') }}</summary>
											<div class="accordion-content">
												<li class="unmodule_detail hov" style="cursor:pointer">
													<div @click="$store.dispatch('set_view', { view: 'loisir-cinema' }); select_adresse_iso(app.cityscan.adresse.cinema)">
														<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-1353-02 ng-star-inserted" style="color: rgb(18, 4, 208);"></span></div>
														<div class="titre_module">{{ $t('cinema') }}</div>
														<div class="note" :class="this.app.cityscan.adresse.cinema.note">{{this.app.cityscan.adresse.cinema.note}}</div>
														<div class="soustitre_module">{{this.app.cityscan.adresse.cinema.nb}} {{this.app.cityscan.adresse.cinema.temps}}</div>
													</div>
													<div class="cz" style="top:18px !important;">
														<label for="adresse_loisir_cinema" class="checkbox" :class="{ checked: adresse_loisir_cinema }">
															<input type="checkbox" :checked="adresse_loisir_cinema"  id="adresse_loisir_cinema" @click=" select_adresse('adresse_loisir_cinema', app.cityscan.adresse.cinema)">
															<span class="chk"><icon file="check" :size="16" /></span>
															<span class="text"></span>
														</label>
													</div>
												</li>
												<div class="divider"></div>
												<li class="unmodule_detail hov" style="cursor:pointer">
													<div @click="$store.dispatch('set_view', { view: 'loisir-bar' }); select_adresse_iso(app.cityscan.adresse.bar)">
														<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-1341-02 ng-star-inserted" style="color: rgb(14, 46, 215);"></span></div>
														<div class="titre_module">{{ $t('bar_et_cafe') }}</div>
														<div class="note" :class="this.app.cityscan.adresse.bar.note">{{this.app.cityscan.adresse.bar.note}}</div>
														<div class="soustitre_module">{{this.app.cityscan.adresse.bar.nb}} {{this.app.cityscan.adresse.bar.temps}}</div>
													</div>
													<div class="cz" style="top:18px !important;">
														<label for="adresse_loisir_bar" class="checkbox" :class="{ checked: adresse_loisir_bar }">
															<input type="checkbox" :checked="adresse_loisir_bar"  id="adresse_loisir_bar" @click=" select_adresse('adresse_loisir_bar', app.cityscan.adresse.bar)">
															<span class="chk"><icon file="check" :size="16" /></span>
															<span class="text"></span>
														</label>
													</div>
												</li>
												<div class="divider"></div>
												<li class="unmodule_detail hov" style="cursor:pointer">
													<div @click="$store.dispatch('set_view', { view: 'loisir-restaurant' }); select_adresse_iso(app.cityscan.adresse.restaurant)">
														<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-1340-02 ng-star-inserted" style="color: rgb(13, 67, 218);"></span></div>
														<div class="titre_module">{{ $t('restaurant') }}</div>
														<div class="note" :class="this.app.cityscan.adresse.restaurant.note">{{this.app.cityscan.adresse.restaurant.note}}</div>
														<div class="soustitre_module">{{this.app.cityscan.adresse.restaurant.nb}} {{this.app.cityscan.adresse.restaurant.temps}}</div>
													</div>
													<div class="cz" style="top:18px !important;">
														<label for="adresse_loisir_restaurant" class="checkbox" :class="{ checked: adresse_loisir_restaurant }" >
															<input type="checkbox" :checked="adresse_loisir_restaurant" id="adresse_loisir_restaurant" @click=" select_adresse('adresse_loisir_restaurant', app.cityscan.adresse.restaurant)">
															<span class="chk"><icon file="check" :size="16" /></span>
															<span class="text"></span>
														</label>
													</div>
												</li>
												<div class="divider"></div>
												<li class="unmodule_detail hov" style="cursor:pointer">
													<div @click="$store.dispatch('set_view', { view: 'loisir-discotheque' }); select_adresse_iso(app.cityscan.adresse.discotheque)">
														<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-1343-02 ng-star-inserted" style="color: rgb(11, 88, 222);"></span></div>
														<div class="titre_module">{{ $t('discotheque') }}</div>
														<div class="note" :class="this.app.cityscan.adresse.discotheque.note">{{this.app.cityscan.adresse.discotheque.note}}</div>
														<div class="soustitre_module">{{this.app.cityscan.adresse.discotheque.nb}} {{this.app.cityscan.adresse.discotheque.temps}}</div>
													</div>
													<div class="cz" style="top:18px !important;">
														<label for="adresse_loisir_discotheque" class="checkbox" :class="{ checked: adresse_loisir_discotheque }">
															<input type="checkbox" :checked="adresse_loisir_discotheque"  id="adresse_loisir_discotheque" @click=" select_adresse('adresse_loisir_discotheque', app.cityscan.adresse.discotheque)">
															<span class="chk"><icon file="check" :size="16" /></span>
															<span class="text"></span>
														</label>
													</div>
												</li>
												<div class="divider"></div>
												<li class="unmodule_detail hov" style="cursor:pointer">
													<div @click="$store.dispatch('set_view', { view: 'loisir-jardin' }); select_adresse_iso(app.cityscan.adresse.jardin)">
														<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-1342-02 ng-star-inserted" style="color: rgb(7, 130, 228);"></span></div>
														<div class="titre_module">{{ $t('parc_et_jardin') }}</div>
														<div class="note" :class="this.app.cityscan.adresse.jardin.note">{{this.app.cityscan.adresse.jardin.note}}</div>
														<div class="soustitre_module">{{this.app.cityscan.adresse.jardin.nb}} {{this.app.cityscan.adresse.jardin.temps}}</div>
													</div>
													<div class="cz" style="top:18px !important;">
														<label for="adresse_loisir_jardin"  class="checkbox" :class="{ checked: adresse_loisir_jardin }" >
															<input type="checkbox" :checked="adresse_loisir_jardin" id="adresse_loisir_jardin" @click=" select_adresse('adresse_loisir_jardin', app.cityscan.adresse.jardin)">
															<span class="chk"><icon file="check" :size="16" /></span>
															<span class="text"></span>
														</label>
													</div>
												</li>
											</div>
										</details>
										<details class="accordion-item" open>
											<summary class="accordion-header" tabindex="0">{{ $t('sports') }}</summary>
											<div class="accordion-content">
												<li class="unmodule_detail hov" style="cursor:pointer">
													<div @click="$store.dispatch('set_view', { view: 'loisir-sport-collectif' }); select_adresse_iso(app.cityscan.adresse.sport_collectif)">
														<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-1360-02 ng-star-inserted" style="color: rgb(0, 108, 148);"></span></div>
														<div class="titre_module">{{ $t('sport_collectif') }}</div>
														<div class="note" :class="this.app.cityscan.adresse.sport_collectif.note">{{this.app.cityscan.adresse.sport_collectif.note}}</div>
														<div class="soustitre_module">{{this.app.cityscan.adresse.sport_collectif.nb}} {{this.app.cityscan.adresse.sport_collectif.temps}}</div>
													</div>
													<div class="cz" style="top:18px !important;">
														<label for="adresse_loisir_sport_collectif" class="checkbox" :class="{ checked: adresse_loisir_sport_collectif }" >
															<input type="checkbox" :checked="adresse_loisir_sport_collectif" id="adresse_loisir_sport_collectif" @click=" select_adresse('adresse_loisir_sport_collectif', app.cityscan.adresse.sport_collectif)">
															<span class="chk"><icon file="check" :size="16" /></span>
															<span class="text"></span>
														</label>
													</div>
												</li>
												<div class="divider"></div>
												<li class="unmodule_detail hov" style="cursor:pointer">
													<div @click="$store.dispatch('set_view', { view: 'loisir-sport-individuel' }); select_adresse_iso(app.cityscan.adresse.sport_individuel)">
														<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-1361-02 ng-star-inserted" style="color: rgb(0, 72, 117);"></span></div>
														<div class="titre_module">{{ $t('sport_individuel') }}</div>
														<div class="note" :class="this.app.cityscan.adresse.sport_individuel.note">{{this.app.cityscan.adresse.sport_individuel.note}}</div>
														<div class="soustitre_module">{{this.app.cityscan.adresse.sport_individuel.nb}} {{this.app.cityscan.adresse.sport_individuel.temps}}</div>
													</div>
													<div class="cz" style="top:18px !important;">
														<label for="adresse_loisir_sport_individuel" class="checkbox" :class="{ checked: adresse_loisir_sport_individuel }" >
															<input type="checkbox" :checked="adresse_loisir_sport_individuel" id="adresse_loisir_sport_individuel" @click=" select_adresse('adresse_loisir_sport_individuel', app.cityscan.adresse.sport_individuel)">
															<span class="chk"><icon file="check" :size="16" /></span>
															<span class="text"></span>
														</label>
													</div>
												</li>
											</div>
										</details>
										<details class="accordion-item" open>
											<summary class="accordion-header" tabindex="0">{{ $t('hebergements_touristiques') }}</summary>
											<div class="accordion-content">
												<li class="unmodule_detail hov" style="cursor:pointer">
													<div @click="$store.dispatch('set_view', { view: 'loisir-hotel' }); select_adresse_iso(app.cityscan.adresse.hotel)">
														<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-1371-02 ng-star-inserted" style="color: rgb(67, 207, 239);"></span></div>
														<div class="titre_module">{{ $t('hôtel') }}</div>
														<div class="note" :class="this.app.cityscan.adresse.hotel.note">{{this.app.cityscan.adresse.hotel.note}}</div>
														<div class="soustitre_module">{{this.app.cityscan.adresse.hotel.nb}} {{this.app.cityscan.adresse.hotel.temps}}</div>
													</div>
													<div class="cz" style="top:18px !important;">
														<label for="adresse_loisir_hotel" class="checkbox" :class="{ checked: adresse_loisir_hotel }" >
															<input type="checkbox" :checked="adresse_loisir_hotel" id="adresse_loisir_hotel" @click=" select_adresse('adresse_loisir_hotel', app.cityscan.adresse.hotel)">
															<span class="chk"><icon file="check" :size="16" /></span>
															<span class="text"></span>
														</label>
													</div>
												</li>
												<div class="divider"></div>
												<li class="unmodule_detail hov" style="cursor:pointer">
													<div @click="$store.dispatch('set_view', { view: 'loisir-hebergement' }); select_adresse_iso(app.cityscan.adresse.hebergement)">
														<div class="icone_module"><span class="cs-tile-icon cs-icon-thematic icon-1373-02 ng-star-inserted" style="color: rgb(63, 194, 221);"></span></div>
														<div class="titre_module">{{ $t('autres_hebergements') }}</div>
														<div class="note" :class="this.app.cityscan.adresse.hebergement.note">{{this.app.cityscan.adresse.hebergement.note}}</div>
														<div class="soustitre_module">{{this.app.cityscan.adresse.hebergement.nb}} {{this.app.cityscan.adresse.hebergement.temps}}</div>
													</div>
													<div class="cz" style="top:18px !important;">
														<label for="adresse_loisir_hebergement" class="checkbox" :class="{ checked: adresse_loisir_hebergement }">
															<input type="checkbox" :checked="adresse_loisir_hebergement"  id="adresse_loisir_hebergement" @click=" select_adresse('adresse_loisir_hebergement', app.cityscan.adresse.hebergement)">
															<span class="chk"><icon file="check" :size="16" /></span>
															<span class="text"></span>
														</label>
													</div>
												</li>
											</div>
										</details>
									</div>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import { mapGetters } from 'vuex'
import PaneAdresseRetour from './PaneAdresseRetour.vue'

export default {
	name: 'PaneAdresseLoisirs',
	data: function() {
		return {
			adresse_loisir_hebergement: true,
			adresse_loisir_hotel: true,
			adresse_loisir_sport_individuel: true,
			adresse_loisir_sport_collectif: true,
			adresse_loisir_jardin: true,
			adresse_loisir_discotheque: true,
			adresse_loisir_restaurant: true,
			adresse_loisir_bar: true,
			adresse_loisir_cinema: true,
			adresse_loisir_musee: true,
			adresse_loisir_theatre: true,
			adresse_loisir_bibliotheque: true,
			detail: '',
		}
	},
	
	computed: Object.assign(mapGetters([ 'app', 'map' ]), {
		
	}),

	mounted: function() {
		// Gestion préférences
		//if (this.app.preferences.adresse_niv1.length) {
		//	this.adresse_loisir_hebergement = (this.app.preferences.adresse_niv2.indexOf('adresse_loisir_hebergement')!==-1)
		//	this.adresse_loisir_hotel = (this.app.preferences.adresse_niv2.indexOf('adresse_loisir_hotel')!==-1)
		//	this.adresse_loisir_sport_individuel = (this.app.preferences.adresse_niv2.indexOf('adresse_loisir_sport_individuel')!==-1)
		//	this.adresse_loisir_sport_collectif = (this.app.preferences.adresse_niv2.indexOf('adresse_loisir_sport_collectif')!==-1)
		//	this.adresse_loisir_jardin = (this.app.preferences.adresse_niv2.indexOf('adresse_loisir_jardin')!==-1)
		//	this.adresse_loisir_discotheque = (this.app.preferences.adresse_niv2.indexOf('adresse_loisir_discotheque')!==-1)
		//	this.adresse_loisir_restaurant = (this.app.preferences.adresse_niv2.indexOf('adresse_loisir_restaurant')!==-1)
		//	this.adresse_loisir_bar = (this.app.preferences.adresse_niv2.indexOf('adresse_loisir_bar')!==-1)
		//	this.adresse_loisir_cinema = (this.app.preferences.adresse_niv2.indexOf('adresse_loisir_cinema')!==-1)
		//	this.adresse_loisir_musee = (this.app.preferences.adresse_niv2.indexOf('adresse_loisir_musee')!==-1)
		//	this.adresse_loisir_theatre = (this.app.preferences.adresse_niv2.indexOf('adresse_loisir_theatre')!==-1)
		//	this.adresse_loisir_bibliotheque = (this.app.preferences.adresse_niv2.indexOf('adresse_loisir_bibliotheque')!==-1)
		//}
	},

	methods: {
		select_adresse: function(e) {
			this[e] = !this[e];
			// Gestion préférences
			//this.$store.dispatch('set_preferences_adresses_niv2', {niv2: e, select: this[e]})
		},
		select_adresse_iso: function(propObj) {
			this.$store.dispatch('set_contour_iso_param', {duration: propObj.duration, transportType: propObj.transportType})
			this.$store.dispatch('set_contour_iso', { contour: true })
		},
		getscore: function(score) {
			let score_name= ''
			if (score >= 80) {
				score_name = "Nutri-score-A"
			} else {
				if (score >= 60) {
					score_name = "Nutri-score-B"
				} else {
					if (score >= 40) {
						score_name = "Nutri-score-C"
					} else {
						if (score >= 20) {
							score_name = "Nutri-score-D"
						} else {
							score_name = "Nutri-score-E"
						}
					}
				}
			}
			return score_name
		},

		details: function(d) {
			if (this.detail==d) {
				this.detail=''
			}
			else {
				this.detail=d
			}
		}
	},
	components: { PaneAdresseRetour }
}
</script>

<style lang="stylus" scoped>
@import '../../assets/css/app'

.pane-adresse-loisirs-enter
	transform translateX(100%)
.pane-adresse-loisirs-leave-to
	transform translateX(-100%)

.pane-adresse-loisirs
	position absolute
	top headersize
	float left
	transition 0.5s easeOutQuart
.cz
	position absolute
	right 4px !important
	top 12px !important

.note
	right 40px !important
	top calc(50% - 8px) !important

.checkbox {
	width: 20px !important;
	height: 20px !important;
}
	
</style>
