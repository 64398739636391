<template>
	<div class="buttonsearch" :class="{distance: (app.view=='ancien-mutations' || app.view=='ancien-offre' || app.view=='rpls')}" v-if="app.authorized && app.efocus_type!='B'">
		<div class="button-report-one" style="width:250px; height:40px" v-show="!app.loading_calc" @click="click()" v-if="app.view=='neuf' || app.view=='' || app.view=='rss'">
			<div class="button">
				{{ $t('rechercher_dans_cette_zone') }}
				<icon file="selection_search" :size="18" />
			</div>
		</div>
		<div class="button-report-one" style="width:250px; height:40px" v-show="!app.loading_calc" @click="setContour(true)" v-else-if="app.view=='locatif'">
			<div class="button">
				{{ $t('mettre_a_jour_le_fond_de_carte') }}
				<icon file="selection_search" :size="18" />
			</div>
		</div>
		<div class="button-report-one dd" v-show="!app.loading_calc" v-else-if="app.view=='ancien-mutations'">
			<div class="box-content">
				<p class="box-title" style="text-align:center">{{distance_ancien_vente}} {{ $t('mètres') }}</p>
				<div class="flex" style="justify-content: space-around;padding-top:5px !important;width:100%; line-height:2rem; vertical-align:middle">
					<div style="white-space: nowrap; padding-right: 10px">{{ $t('Dans un rayon de') }}</div>
					<div class="flex" style="width:100%">
						<div style="justify-content: space-around;padding-top:15px !important;line-height:2rem; vertical-align:middle;width:100%">
							<Slider v-model="distance_ancien_vente" :step="50" :min="50" :max="10000" @input="debouncedAncienVente" />
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="button-report-one dd" v-show="!app.loading_calc" v-else-if="app.view=='ancien-offre'">
			<div class="box-content">
				<p class="box-title" style="text-align:center">{{distance_ancien_offre}} {{ $t('mètres') }}</p>
				<div class="flex" style="justify-content: space-around;padding-top:5px !important;width:100%; line-height:2rem; vertical-align:middle">
					<div style="white-space: nowrap; padding-right: 10px">{{ $t('Dans un rayon de') }}</div>
					<div class="flex" style="width:100%">
						<div style="justify-content: space-around;padding-top:15px !important;line-height:2rem; vertical-align:middle;width:100%">
							<Slider v-model="distance_ancien_offre" :step="50" :min="50" :max="5000" @change="onSlideEndAncienOffre" />
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="button-report-one dd" v-show="!app.loading_calc" v-else-if="app.view=='rpls'">
			<div class="box-content">
				<p class="box-title" style="text-align:center">{{distance_rpls}} {{ $t('mètres') }}</p>
				<div class="flex" style="justify-content: space-around;padding-top:5px !important;width:100%; line-height:2rem; vertical-align:middle">
					<div style="white-space: nowrap; padding-right: 10px">{{ $t('Dans un rayon de') }}</div>
					<div class="flex" style="width:100%">
						<div style="justify-content: space-around;padding-top:15px !important;line-height:2rem; vertical-align:middle;width:100%">
							<Slider v-model="distance_rpls" :step="50" :min="50" :max="5000" @change="onSlideEndRpls" />
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Slider from 'primevue/slider';
import { debounce } from 'lodash';

export default {
	name: 'ButtonReport',
	data: function() {
		return {
			distance_ancien_vente: 200,
			distance_ancien_offre: 200,
			distance_rpls: 200
		}
	},
	watch: {
		'app.filtre_ancien.distance': function(newValue) {
			this.distance_ancien_vente = newValue
			//this.valide_filtre()
		},		
		'app.filtre_ancien_offre.distance': function(newValue) {
			this.distance_ancien_offre = newValue
			//this.valide_filtre()
		},		
		'app.filtre_rpls.distance': function(newValue) {
			this.distance_rpls = newValue
			//this.valide_filtre()
		},		
	},

	computed: Object.assign(mapGetters([ 'app', 'map' ]), {

	}),

	created() {
		this.debouncedAncienVente = debounce(this.onSlideEndAncienVente, 300);
	},

	methods: {
		// eslint-disable-next-line 
		onSlideEndRpls: function(event) {
			this.applique_filtre_rpls()
		},

		// eslint-disable-next-line 
		onSlideEndAncienOffre: function(event) {
			this.applique_filtre_ancien_offre()
		},

		// eslint-disable-next-line 
		onSlideEndAncienVente: function(event) {
			console.log('onSlideEndAncienVente')
			this.applique_filtre_ancien_vente()
		},

		applique_filtre_rpls: function() {
			this.$store.dispatch('set_mapdistancerpls', {mapdistancerpls: this.distance_rpls})
		},

		applique_filtre_ancien_vente: function() {
			this.$store.dispatch('set_mapdistance', {mapdistance: this.distance_ancien_vente})
		},

		applique_filtre_ancien_offre: function() {
			this.$store.dispatch('set_mapdistanceoffre', {mapdistanceoffre: this.distance_ancien_offre})
		},

		setContour: function(el) {
			this.$store.dispatch('set_contour_loyer', { contour: el })
		},

		click: function() {
			this.$store.dispatch('set_nbprogselect', {nb : 0})
			if (!this.app.nolive) {
				try {
					for (let i in this.app.programs_filter) {
						if (document.getElementById(this.app.programs_filter[i].id + "-" + this.app.programs_filter[i].ville)) {
						document.getElementById(this.app.programs_filter[i].id + "-" + this.app.programs_filter[i].ville).className = 'checkbox'
					}					
					}
				} catch (error) {
					console.log(error, 'error')
				}
			}
			this.$store.dispatch('set_search', {type: 'boundingbox', danscettezone: true})
		},

		replace: function() {
			this.$store.dispatch('set_nbprogselect', {nb : 0})
			if (!this.app.nolive) {
				for (let i in this.app.programs_filter) {
					if (document.getElementById(this.app.programs_filter[i].id + "-" + this.app.programs_filter[i].ville)) {
						document.getElementById(this.app.programs_filter[i].id + "-" + this.app.programs_filter[i].ville).className = 'checkbox'
					}					
				}
			}
			if (this.app.adr_init.type =='city') {
				this.$store.dispatch('set_search', this.app.adr_init)
			} else {
				this.$store.map_osm.setView(new L.LatLng(this.app.mrk.lat, this.app.mrk.lng))
			}
		},
		
		removeall: function() {
			this.$store.dispatch('select_programs', { programs: this.app.programs, selected: false })
		}
	},

	components: { Slider }
}
</script>

<style lang="stylus">
@import '../../assets/css/app'

.infinite-loader
	position absolute
	right 16px
	top calc(70% - 12px)
	.loader
		display inline-block
		background main_color
		border-radius 100%
		color #fff
		.icon
			display block
			animation rotate 1.5s infinite linear

@keyframes rotate
	from
		transform rotate(0deg)
	to
		transform rotate(359deg)

.2r
	width 2rem !important
	height 2rem !important

.distance
	left calc(50% - 240px) !important
.buttonsearch
	position absolute
	left calc(50% - 121px)
	bottom 0px
	z-index 450
	display inline-flex
	.button-report-one
		margin-right 10px
		transition 0.5s easeOutQuart
		.button
			text-align center
			vertical-align middle
			z-index 500
			background #fff
			-webkit-box-shadow 0 3px 6px alpha(#000, 25%)
			box-shadow 0 3px 6px alpha(#000, 25%)
			cursor pointer
			padding-top 7px
			text-align right
			color #666
			height 30px
			cursor pointer
			&:hover
				background-color secondary
				color white
			.icon
				right 210px

	.button-report-two
		width 140px
		transition 0.5s easeOutQuart
		.button-two
			padding 7px 10px 10px 10px
			text-align right
			border-radius radius 5px
			background white
			color #666
			height 30px
			cursor pointer
			box-shadow 0 0 4px alpha(#000, 25%)
			&:hover
				background-color darken(blue, 10%)
				color white
			.icon
				right 108px
.loading-icon
	animation rotate 2s infinite linear
	margin 0 auto

.dd
	width 450px !important
	padding 10px
	margin-bottom 10px
	background-color #fff
	border-radius 8px
	border 1px solid rgb(204, 204, 204)
	border-radius 8px
	border 1px solid rgb(204, 204, 204)

</style>
